import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageUrl } from '../../../types';

const Logo: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    if (location.pathname === PageUrl.Website) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate(PageUrl.Website);
    }
  };

  return (
    <div className="flex items-center cursor-pointer" onClick={handleClick}>
      <img src="./logo512.png" alt="Logo" className="h-8 w-8 mr-2" />
      <span className="text-lg font-semibold">Booklet</span>
    </div>
  );
};

export default Logo;
