import { Card, Language } from "../types";

const addVerbParticle = (lemma: string, lang: Language): string => {
    switch (lang) {
        case Language.German:
            return `zu ${lemma}`;
        case Language.English:
            return 'to ' + lemma;
        default:
            return lemma;
    }
}

const addNounParticle = (lemma: string, lang: Language, gender?: string): string => {
    switch (lang) {
        case Language.German:
            switch (gender) {
                case 'Masculine':
                    return `der ${lemma}`;
                case 'Feminine':
                    return `die ${lemma}`;
                case 'Neuter':
                    return `das ${lemma}`;
                default:
                    return lemma;
            }
        case Language.English:
            return 'the ' + lemma;
        default:
            return lemma;
    }
}

export const addParticleToLemma = (lang: Language, lemma: string, pos?: string, gender?: string): string => {
    console.log('lang', lang, 'lemma', lemma, 'pos', pos, 'gender', gender);
    switch (pos) {
        case 'verb':
            return addVerbParticle(lemma, lang);
        case 'noun':
            return addNounParticle(lemma, lang, gender);
        default:
            return lemma;
    }
}

export const addParticlesToLemmas = (cards: Card[], langFrom: Language, langTo: Language): Card[] => {
    const cardsWithParticles = cards.map((card) => {
        card.lemmaLangFrom = addParticleToLemma(langFrom, card.lemmaLangFrom, card.pos, card.genderLangFrom);
        card.lemmaLangTo = addParticleToLemma(langTo, card.lemmaLangTo, card.pos, card.genderLangTo);
        return card;
    });
    return cardsWithParticles;
}
