import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../authentification/AuthContext';
import handleStartVocabularyGame from '../../utils/startVocabularyGame';
import LoadingAnimation from '../baseComponents/LoadingAnimation';
import { ExerciseType, GameMode } from '../../types';

interface GameCardProps {
  title: string;
  image: string;
  gameMode: GameMode;
  exerciseType: ExerciseType;
}

export const GameCard: React.FC<GameCardProps> = ({ title, image, gameMode, exerciseType }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  return user && (
    <button
      className={`flex rounded-xl bg-bg-secondary shadow-md border border-border-secondary flex-row pt-4 pr-0 pb-0 pl-6 md:h-full 
        hover:shadow-lg hover:border-border-tertiary hover:bg-bg-tertiary`}
      onClick={() => handleStartVocabularyGame(gameMode, exerciseType, user, navigate, setIsLoading)}
    >
      <div className="flex gap-3 text-left flex-row md:flex-col justify-between flex-1">
        <h1 className="text-xl font-medium text-text-primary whitespace-nowrap md:text-2xl">
          {title}
        </h1>
        <img src={image} alt={title} className="mt-2 w-24 md:w-36" />
      </div>
      <LoadingAnimation isLoading={isLoading} />
    </button>
  );
};
