import React from 'react';
import SearchIcon from '../../assets/images/search-lg.svg';
import { useLanguage } from '../../languages/LanguageContext';
import Input from './Input';

type SearchBarProps = {
  filter: string;
  setFilter: (filter: string) => void;
  placeholder?: string;
  className?: string;
};

const SearchBar: React.FC<SearchBarProps> = ({ filter, setFilter, placeholder, className }) => {
  const { getTextLangFrom } = useLanguage();

  return (
    <div className={`flex flex-col gap-1.5 mr-auto ${className}`}>
      <div className="relative">
        <img
          src={SearchIcon}
          alt="Search"
          className="absolute top-1/2 left-3 transform -translate-y-1/2 w-5 h-5"
        />
        <Input
          type="text"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder={placeholder || getTextLangFrom('SearchBar-placeholder')}
          className=""
          hasIcon={true}
        />
      </div>
    </div>
  );
};

export default SearchBar;
