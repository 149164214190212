import React from 'react';

interface TooltipProps {
  text: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
  isVisible: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({ text, position = 'top', isVisible }) => {
  // Define tooltip position classes
  const tooltipPositionStyles = {
    top: 'bottom-full left-1/2 transform -translate-x-1/2 mb-2',
    bottom: 'top-full left-1/2 transform -translate-x-1/2 mt-2',
    left: 'right-full top-1/2 transform -translate-y-1/2 mr-2',
    right: 'left-full top-1/2 transform -translate-y-1/2 ml-2',
  };

  return (
    <div
      className={`absolute px-2 py-1 text-xs bg-bg-secondary text-text-secondary border border-border-primary rounded whitespace-nowrap
        ${isVisible ? 'block' : 'hidden'} 
        ${tooltipPositionStyles[position] || tooltipPositionStyles.top}`}
    >
      {text}
    </div>
  );
};

export default Tooltip;
