import React from 'react';
import { useLanguage } from '../../languages/LanguageContext';
import OnboardingMultipleChoiceTemplate from './OnboardingMultipleChoiceTemplate';
import { OnboardingData, OnboardingOption } from '../../types';
import { useLocation } from 'react-router-dom';


const OnboardingLanguage: React.FC = () => {
  const { getTextLangFrom } = useLanguage();
  const onboardingOptions: OnboardingOption[] = [
    { code: 'A1', text: getTextLangFrom('cefr-level-A1') },
    { code: 'A2', text: getTextLangFrom('cefr-level-A2') },
    { code: 'B1', text: getTextLangFrom('cefr-level-B1') },
    { code: 'B2', text: getTextLangFrom('cefr-level-B2') },
  ];
  const location = useLocation();
  const onboardingState = location.state?.onboardingState as OnboardingData;

  return (
    <OnboardingMultipleChoiceTemplate
      step={3}
      title={getTextLangFrom('OnboardingPage-level-title') + ' ' + getTextLangFrom(`language-${onboardingState.language}`) + '?'}
      onboardingOptions={onboardingOptions}
    />
  );
};

export default OnboardingLanguage;