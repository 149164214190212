// useFlashCardKeyboardControls.ts

import { useEffect } from 'react';

const useFlashCardKeyboardControls = (
  hasFlipped: boolean,
  toggleCardFlip: () => void,
  handleCorrectScore: () => void,
  handleIncorrectScore: () => void
) => {
  
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === ' ' || event.key === 'Spacebar' || event.key === 'Enter' || (!hasFlipped && event.key === 'ArrowRight')) {
        event.preventDefault();
        toggleCardFlip();
      } else if (hasFlipped && event.key === 'ArrowRight') {
        event.preventDefault();
        handleCorrectScore();
      } else if (hasFlipped && event.key === 'ArrowLeft') { 
        event.preventDefault();
        handleIncorrectScore();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [hasFlipped, toggleCardFlip, handleCorrectScore, handleIncorrectScore]);
};

export default useFlashCardKeyboardControls;
