import React, { useEffect, useRef } from 'react';
import { useLanguage } from '../../languages/LanguageContext';

const LoadingAnimation: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const { getTextLangFrom } = useLanguage();

  return (
    <div className={`fixed top-0 left-0 h-screen w-screen bg-bg-overlay backdrop-blur-lg z-50 flex ${isLoading ? 'visible' : 'hidden'}`}>
      {/* Uncomment if you want to add back button */}
      {/* <NavbarBack onBackClick={() => navigate(GameUrl....)} /> */}
      <CanvaWithFireworks />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-2xl italic text-text-primary">
        {getTextLangFrom('word-loading-dots')}
      </div>
    </div>
  );
};

export default LoadingAnimation;

const CanvaWithFireworks: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (!canvas || !context) return;

    // Adjust colors using themes
    const colors = [
      '#227C9D',
      '#1DA0A8',
      '#17C3B2',
      '#FFCB77',
      '#FFE2B3',
      '#FEF9EF',
      '#FEB3B1',
      '#FE6D73',
    ];

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const fireworks: Firework[] = [];
    let timer: number | undefined;

    const createFirework = () => {
      const centerX = canvas.width * Math.random();
      const centerY = canvas.height * Math.random();
      fireworks.push(new Firework(centerX, centerY, colors));
    };

    const updateFireworks = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      fireworks.forEach((firework, index) => {
        firework.update();
        firework.draw(context);
        if (firework.done) {
          fireworks.splice(index, 1);
        }
      });
    };

    const animate = () => {
      updateFireworks();
      timer = requestAnimationFrame(animate);
    };

    const createAndScheduleFireworks = () => {
      createFirework();
      const nextFireworkDelay = Math.random() * 2000 + 500; // Random delay between 0.5 to 2.5 seconds
      setTimeout(createAndScheduleFireworks, nextFireworkDelay);
    };

    // Initial fireworks creation
    createAndScheduleFireworks();
    animate();

    return () => {
      cancelAnimationFrame(timer!);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="fixed top-0 left-0 w-full h-full z-50" 
    />
  );

}

class Firework {
  x: number;
  y: number;
  done: boolean;
  particles: Particle[];

  constructor(x: number, y: number, colors: string[]) {
    this.x = x;
    this.y = y;
    this.done = false;
    this.particles = [];
    this.createParticles(colors);
  }

  createParticles(colors: string[]) {
    const particleCount = 10; // Adjusted for performance
    for (let i = 0; i < particleCount; i++) {
      setTimeout(() => {
        const randomX = this.x + (Math.random() - 0.5) * 100; // Random X offset within 100 units
        const randomY = this.y + (Math.random() - 0.5) * 100; // Random Y offset within 100 units
        this.particles.push(new Particle(randomX, randomY, colors));
      }, i * 20); // 20ms delay between each particle creation
    }
  }

  update() {
    // Remove particles that have faded out
    this.particles = this.particles.filter((particle) => particle.alpha > 0.01);
    // Update remaining particles
    this.particles.forEach((particle) => particle.update());
    // Check if any particles remain
    this.done = this.particles.length === 0;
  }

  draw(context: CanvasRenderingContext2D) {
    this.particles.forEach((particle) => particle.draw(context));
  }
}

class Particle {
  x: number;
  y: number;
  vx: number;
  vy: number;
  alpha: number;
  size: number;
  color: string;
  angle: number;
  angleIncrement: number;
  lifespan: number; // Added lifespan property in milliseconds
  startTime: number; // Timestamp when the particle was created

  constructor(x: number, y: number, colors: string[]) {
    this.x = x;
    this.y = y;
    this.vx = (Math.random() - 0.5) * 4;
    this.vy = (Math.random() - 0.5) * 4;
    this.alpha = 1;
    this.size = Math.random() * 3 + 0.3;
    this.color = colors[Math.floor(Math.random() * colors.length)];
    this.angle = Math.random() * Math.PI * 2;
    this.angleIncrement = (Math.random() - 0.5) * 0.1;
    this.lifespan = 4000; // 4 seconds lifespan
    this.startTime = Date.now(); // Record creation time
  }

  update() {
    const now = Date.now();
    const elapsedTime = now - this.startTime;
    const progress = elapsedTime / this.lifespan;

    if (progress >= 1) {
      this.alpha = 0; // Particle fades out completely
    } else {
      this.alpha = 1 - progress; // Fade out gradually
    }

    const speed = Math.random() * 2 + 1;
    this.x += Math.cos(this.angle) * speed;
    this.y += Math.sin(this.angle) * speed;
    this.angle += this.angleIncrement;
  }

  draw(context: CanvasRenderingContext2D) {
    if (this.alpha <= 0) return; // Skip drawing if particle is completely faded out

    context.save();
    context.globalAlpha = this.alpha;
    context.beginPath();
    context.arc(this.x, this.y, this.size, 0, Math.PI * 2);
    context.fillStyle = this.color;
    context.fill();
    context.restore();
  }
}

