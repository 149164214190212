import React from 'react';
import { BookExercise, Language, PageUrl, TranslationTooltip } from "../../../types";
import setTooltipPosition from './tooltipPosition';
import setGoogleTranslation from './useSetGoogleTranslation';

const showTooltiponWordClick = async (
  event: React.MouseEvent<HTMLSpanElement>,
  part: BookExercise | string,
  setTooltip: React.Dispatch<React.SetStateAction<TranslationTooltip>>,
  tooltip: TranslationTooltip,
  langFrom: Language,
  langTo: Language,
  pageUrl: PageUrl,
) => {
  tooltip = setTooltipPosition(tooltip, pageUrl, undefined, event);

  if (typeof part === 'string') {
    await setGoogleTranslation(tooltip, setTooltip, pageUrl, part, langFrom, langTo);
  } else {
    tooltip.bookExercise = part; 
    setTooltip({ ...tooltip, isVisible: true });
  }
}

export default showTooltiponWordClick;