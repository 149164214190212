import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { getUserByEmail, checkUserStatusByEmail, updateUser as updateUserAPI } from '../api/Users';
import { User, AuthContextType, UserStatus } from '../types';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const storedAuthState = localStorage.getItem('isAuthenticated');
    const storedUser = localStorage.getItem('user');

    if (storedAuthState === 'true' && storedUser) {
      setIsAuthenticated(true);
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const loginWithGoogle = async (email: string) => {
    try {
      const userData = await getUserByEmail(email);

      if (userData) {
        setUser(userData);
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('user', JSON.stringify(userData));
      } else {
        throw new Error('User not found');
      }
    } catch (error) {
      console.error('Google login failed:', error);
      throw new Error('Google login failed');
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('user');
  };

  const checkUserStatus = async (email: string): Promise<UserStatus> => {
    try {
      return await checkUserStatusByEmail(email);
    } catch (error) {
      return UserStatus.ServerError;
    }
  };

  const updateLocalUser = useCallback((updatedUser: User) => {
    // Update only the local state and localStorage
    setUser(updatedUser);
    localStorage.setItem('user', JSON.stringify(updatedUser));
  }, []);

  const updateLocalAndDbUser = useCallback(async (updatedUser: User): Promise<void> => {
    try {
      const updated = await updateUserAPI(updatedUser);
      if (updated) {
        updateLocalUser(updatedUser); // Update local state upon successful backend update
      } else {
        console.error('Failed to update user in backend');
      }
    } catch (error) {
      console.error('Error updating user in backend:', error);
    }
  }, [updateLocalUser]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loginWithGoogle,
        logout,
        updateLocalUser,
        updateLocalAndDbUser,
        checkUserStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
