import React, { useState } from 'react';

interface TabProps {
  text: string;
  handleClick: () => void;
}

interface HorizontalMenuProps {
  tabs: TabProps[];
  size?: 'sm' | 'md' | 'lg';
}

const HorizontalMenu: React.FC<HorizontalMenuProps> = ({ tabs, size = 'md' }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleTabClick = (index: number, handleClick: () => void) => {
    setSelectedIndex(index);
    handleClick();
  };

  const sizeClasses = {
    sm: 'py-2 px-3 text-sm',
    md: 'py-2 px-4 text-base',
    lg: 'py-3 px-6 text-lg',
  };

  return (
    <div className="flex w-full border-b border-border-secondary">
      {tabs.map((tab, index) => (
        <div key={index} className="flex flex-col justify-between">
          <button
            className={`flex items-center cursor-pointer mx-0.5 rounded-xl font-medium
              ${sizeClasses[size]}
              ${selectedIndex === index
                ? 'bg-tab-bgSelected text-tab-fgSelected hover:bg-tab-bgSelected hover:text-tab-fgSelected'
                : 'text-tab-fgUnselected hover:bg-tab-bgHover hover:text-tab-fgHover'}
            `}
            onClick={() => handleTabClick(index, tab.handleClick)}
          >
            {tab.text}
          </button>
          <div
            className={`h-0.5 mt-1.5 mb-[-1px] ${
              selectedIndex === index ? 'bg-tab-fgSelected' : 'bg-transparent'
            }`}
          />
        </div>
      ))}
    </div>
  );
};

export default HorizontalMenu;
