import React from 'react';
import { getGoogleTranslation } from "../../../api/Messages";
import { Language, PageUrl, TranslationTooltip } from "../../../types";

const setGoogleTranslation = async (
  tooltip: TranslationTooltip,
  setTooltip: React.Dispatch<React.SetStateAction<TranslationTooltip>>,
  pageUrl: PageUrl,
  text: string,
  langFrom: Language,
  langTo: Language
) => {
  if (pageUrl === PageUrl.Website) {
    setTooltip({ ...tooltip, errorMessage: "Log in to translate", isLoading: false, isVisible: true });
  } else {
    setTooltip({ ...tooltip, isVisible: true, isLoading: true });
    const googleTranslation = await getGoogleTranslation(text, langTo, langFrom);
    if (googleTranslation) {
      // console.log('googleTranslation', googleTranslation);
      setTooltip({ ...tooltip, googleTranslation: googleTranslation, isLoading: false, isVisible: true });
    } else {
      setTooltip({ ...tooltip, errorMessage: "Timeout error", isLoading: false, isVisible: true });
    }
  }
}

export default setGoogleTranslation;