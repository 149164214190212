import { translations } from "../../../languages/LanguageContext";
import { CefrLevel, Language, Message, MessageRole } from "../../../types";
import createMessagesWithBookExercises from "../../book/bookUtils/createMessageWithExercises";


function fetchDemoMessages(language: Language) {
    // Read the bookResponse from /books/it.json
    const bookResponse = require(`./DemoSection/books/${language}.json`);
    const chapterMessages = createMessagesWithBookExercises(bookResponse.text, bookResponse.bookExercises, CefrLevel.A2);
    const titleMessage = { messageRole : MessageRole.Title, bookExercises: [bookResponse.title || translations[language]['word-chapter'] + ' ' + (bookResponse.originalChapterNumber + 1)] } as Message;
    const introMessage = { messageRole: MessageRole.Intro, bookExercises: [`${translations[language]['word-part']} ${bookResponse.chapterNumberWithinOriginalChapter}`] } as Message;

    return [titleMessage, introMessage, ...chapterMessages];
};

export default fetchDemoMessages;
