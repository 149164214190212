import React from 'react';

interface BookCardProgressProps {
  progress: number;
}

const BookCardProgress: React.FC<BookCardProgressProps> = ({ progress }) => {
  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center w-24 h-2.5 bg-bg-secondary rounded-full overflow-hidden">
        <div
          className="h-full bg-bg-brand rounded-full"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};

export default BookCardProgress;