import { createContext, useContext, useEffect, ReactNode } from 'react';
import { Theme } from '../types';
import { useAuth } from '../authentification/AuthContext';

const ThemeContext = createContext({
  changeTheme: (theme: Theme) => {},
});

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();

  // Set theme based on user preference or system theme when the user is not logged in
  useEffect(() => {
    const userTheme = user?.theme as Theme | null;

    if (userTheme) {
      changeTheme(userTheme);
    } else {
      // Check system theme if no user preference is defined
      const systemThemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const systemTheme = systemThemeQuery.matches ? Theme.Dark : Theme.Light;

      changeTheme(systemTheme);

      // Listen for system theme changes
      const updateSystemTheme = (e: MediaQueryListEvent) => {
        const newSystemTheme = e.matches ? Theme.Dark : Theme.Light;
        changeTheme(newSystemTheme);
      };
      systemThemeQuery.addEventListener('change', updateSystemTheme);

      // Cleanup listener on component unmount
      return () => systemThemeQuery.removeEventListener('change', updateSystemTheme);
    }
  }, [user]);

  const changeTheme = (newTheme: Theme) => {
    const root = document.documentElement;
    Object.values(Theme).forEach(themeClass => root.classList.remove(themeClass));
    root.classList.add(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
