// Async function to get valid client ID based on environment and connectivity
const fetchValidClientId = async () => {
    const projectId = process.env.GCLOUD_PROJECT;
    const userAgent = typeof window !== 'undefined' ? window.navigator.userAgent : '';
    const defaultClientId = process.env.REACT_APP_PRODUCTION_WEB_CLIENT_ID;
    let clientId = defaultClientId; // Default fallback

    if (projectId === process.env.REACT_APP_STAGING_PROJECT_ID) {
      // Staging environment
      clientId = /android/i.test(userAgent)
        ? process.env.REACT_APP_PRODUCTION_ANDROID_CLIENT_ID
        : /iPad|iPhone|iPod/.test(userAgent)
        ? process.env.REACT_APP_PRODUCTION_IOS_CLIENT_ID
        : process.env.REACT_APP_STAGING_WEB_CLIENT_ID;
    } else if (projectId === process.env.REACT_APP_PRODUCTION_PROJECT_ID) {
      // Production environment
      clientId = /android/i.test(userAgent)
        ? process.env.REACT_APP_ANDROID_CLIENT_ID
        : /iPad|iPhone|iPod/.test(userAgent)
        ? process.env.REACT_APP_PRODUCTION_IOS_CLIENT_ID
        : process.env.REACT_APP_PRODUCTION_WEB_CLIENT_ID;
    }
  
    try {
      const response = await fetch(`https://oauth2.googleapis.com/tokeninfo?client_id=${clientId}`);
      // console.log('Google connectivity response.ok:', response.ok, 'Client ID:', clientId, 'Default:', defaultClientId);
      return response.ok ? clientId : defaultClientId;
    } catch (error) {
      // console.error('Google connectivity error:', error, '. Returning default client ID:', defaultClientId);
      return defaultClientId;
    }
  };

export default fetchValidClientId;