import { useNavigate } from 'react-router-dom';
import { terminateGame } from '../api/Games';
import { Card, GameMode, GameState, ExerciseType, User, PageUrl } from '../types';

const evaluateNextExerciseTypeAndMode = (gameMode: GameMode, exerciseType: ExerciseType): { nextGameMode: GameMode, nextExerciseType: ExerciseType } => {
  switch (exerciseType) {
    case ExerciseType.Vocabulary:
      return { nextGameMode: gameMode, nextExerciseType: ExerciseType.Vocabulary };
    case ExerciseType.BookChapterReview:
      return { nextGameMode: GameMode.Book, nextExerciseType: ExerciseType.BookGeneralReview};
    case ExerciseType.BookGeneralReview:
      return { nextGameMode: GameMode.Book, nextExerciseType: ExerciseType.NextChapter};
    default:
      return { nextGameMode: gameMode, nextExerciseType: ExerciseType.Vocabulary };
  }
}

const handleTerminateVocabularyGame = async (
  gameMode: GameMode,
  exerciseType: ExerciseType,
  user: User,
  navigate: ReturnType<typeof useNavigate>,
  cards: Card[],
  initialTime: Date,
  isEndOfGame: boolean,
  chatId?: number,
  numberCardsToReview?: number,
  isChapterInterrupted: boolean = false,
) => {

  if (!user) {
      navigate(PageUrl.Library);
      return null;
  }

  // console.log('gameMode', gameMode, 'chatId', chatId, 'exerciseType', exerciseType, 'initialTime', initialTime, 'isEndOfGame', isEndOfGame);

  let wrongAnswers = 0;
  let cardsWithScore = [] as Card[];

  cards.forEach((card: Card) => {
    if (card.score !== undefined) {
      cardsWithScore.push(card);

      if (card.score === false) {
        wrongAnswers++;
      }
    }
  });

  // save the results of the game
  terminateGame(user.id, gameMode, cardsWithScore, new Date(), chatId, isChapterInterrupted);

  const { nextGameMode, nextExerciseType } = evaluateNextExerciseTypeAndMode(gameMode, exerciseType);

  if (isEndOfGame) {
    const updatedGameState = {
      gameMode: nextGameMode,
      accuracy: (cards.length - wrongAnswers) / cards.length,
      gameDuration: (new Date().getTime() - initialTime.getTime()) / 1000,
      nextExerciseType: nextExerciseType,
      numberCardsToReview: numberCardsToReview,
      chatId: chatId,
    } as GameState;
    navigate(PageUrl.EndGame, { state: { gameState: updatedGameState } });
  } else {
    if (nextExerciseType === ExerciseType.Vocabulary) {
      navigate(PageUrl.Exercises);
    } else {
      navigate(PageUrl.Library);
    }
  }
};

export default handleTerminateVocabularyGame;