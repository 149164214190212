// src/routes/AppRoutes.tsx

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../authentification/PrivateRoute';
import LibraryPage from '../components/library/LibraryPage';
import ExercisePage from '../components/exercises/ExercisePage';
import FlashCardPage from '../components/games/FlashCardPage/FlashCardPage';
import MultiplechoicePage from '../components/games/MultipleChiocePage/MultipleChoicePage';
import WritePage from '../components/games/WritePage/WritePage';
import BookPage from '../components/book/BookPage';
import ProfilePage from '../components/profile/ProfilePage';
import SettingsPage from '../components/settings/SettingsPage';
import EndGameStatsPage from '../components/endGame/EndGameStatsPage';
import EndGameStreakPage from '../components/endGame/EndGameStreakPage';
import EndChapterPage from '../components/endGame/EndChapterPage';
import BookUploadPage from '../components/library/BookUploadPage';
import BookEditPage from '../components/library/BookEditPage';
import OnboardingWelcome from '../components/onboarding/OnboardingWelcome';
import OnboardingLanguage from '../components/onboarding/OnboardingLanguage';
import OnboardingLevel from '../components/onboarding/OnboardingLevel';
import { PageUrl } from '../types';
import NotFoundPage from '../components/notFound/NotFoundPage';
import LoginPage from '../components/login/LoginPage';
import PrivacyPolicyPage from '../components/website/TextPages/PrivacyPolicyPage';
import HomePage from '../components/website/WebsiteHomePage/HomePage';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Redirects */}
      {/* <Route path="/" element={<Navigate to={PageUrl.Library} />} /> */}
      <Route path="*" element={<NotFoundPage />} />

      {/* Website routes */}
      <Route path="/" element={<HomePage />} />

      {/* App non-private routes*/}
      <Route path={PageUrl.Login} element={<LoginPage />} />
      <Route path={PageUrl.SignUp} element={<LoginPage />} />
      <Route path={PageUrl.PrivacyPolicy} element={<PrivacyPolicyPage />} />

      {/* App private routes */}
      <Route element={<PrivateRoute />}>
        <Route path={PageUrl.Library} element={<LibraryPage />} />
        <Route path={PageUrl.OnboardingWelcome} element={<OnboardingWelcome />} />
        <Route path={PageUrl.OnboardingLanguage} element={<OnboardingLanguage />} />
        <Route path={PageUrl.OnboardingLevel} element={<OnboardingLevel />} />
        <Route path={PageUrl.Exercises} element={<ExercisePage />} />
        <Route path={PageUrl.Flashcard} element={<FlashCardPage />} />
        <Route path={PageUrl.MultipleChoice} element={<MultiplechoicePage />} />
        <Route path={PageUrl.Write} element={<WritePage />} />
        <Route path={PageUrl.Book} element={<BookPage />} />
        <Route path={PageUrl.Profile} element={<ProfilePage />} />
        <Route path={PageUrl.Settings} element={<SettingsPage />} />
        <Route path={PageUrl.EndGame} element={<EndGameStatsPage />} />
        <Route path={PageUrl.StreakExtension} element={<EndGameStreakPage />} />
        <Route path={PageUrl.BookReviewExercises} element={<EndChapterPage />} />
        <Route path={PageUrl.BookUpload} element={<BookUploadPage />} />
        <Route path={PageUrl.BookEdit} element={<BookEditPage />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
