import { Book, ChapterForNavigation, BookExercise, User, Language, BookResponse } from '../types';
import { apiUrl, fetchData } from './api';


export async function uploadBook(book_file: File, userId: number, langTo: Language): Promise<Book> {
  const formData = new FormData();
  formData.append('file', book_file);
  formData.append('userId', userId.toString());
  formData.append('langTo', langTo);
  const response = await fetch(`${apiUrl}/book/upload`, {
    method: 'POST',
    body: formData
  });
  if (!response.ok) {
    throw new Error('Failed to upload file');
  }
  return await response.json();
}

export async function startNewChapter(user: User, bookId?: number, chatId?: number, numberNextChapter?: number): Promise<BookResponse> {
  return await fetchData<BookResponse>(`${apiUrl}/book/new-chapter`, 'POST', { userId: user.id, langFrom: user.langFrom, langTo: user.langTo, bookId, chatId, numberNextChapter });
}

export async function getBooksAndStreak(userId: number, langTo: Language): Promise<{ numberStreakDays: number, last7dStreakDays: boolean[], books: Book[] }> {
  return await fetchData<{ numberStreakDays: number, last7dStreakDays: boolean[], books: Book[] }>(`${apiUrl}/book-and-streak`, 'POST', { userId, langTo });
}

export async function updateBook(formData: FormData): Promise<void> {
  const response = await fetch(`${apiUrl}/book/update`, {
    method: 'POST',
    body: formData
  });
  if (!response.ok) {
    throw new Error('Failed to upload file');
  }
  return await response.json();
}

export async function deleteBook(bookId: number, userId: number, langTo: Language): Promise<Book[]> {
  return await fetchData<Book[]>(`${apiUrl}/book/delete`, 'POST', { bookId, userId, langTo });
}

export async function getChaptersForNavigation(bookId: number): Promise<ChapterForNavigation[]> {
  return await fetchData<ChapterForNavigation[]>(`${apiUrl}/book/chapters-for-navigation?bookId=${bookId}`, 'GET');
}
