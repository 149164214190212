import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Fireworks from '../baseComponents/FireworksAnimation';
import ChevronRightWhite from '../../assets/imagesApp/chevron-right-white.svg';
import FooterControls from '../appComponents/FooterControls';
import { useLanguage } from '../../languages/LanguageContext';
import Button from '../baseComponents/Button';
import EndGameLayout from './EndGameLayout';
import { getOrderedDaysOfWeek, StreakDays } from '../profile/StatsTiles';
import { useAuth } from '../../authentification/AuthContext';
import StreakIcon from '../../assets/imagesApp/streakIconActive.svg';
import { PageUrl } from '../../types';


const EndGameStreakPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { getTextLangFrom } = useLanguage();
    const { user } = useAuth();
    const last7dStreakDays = user?.last7dStreakDays || [];
  
    const orderedDaysOfWeek = getOrderedDaysOfWeek();
    const lastWeekDailyStats = last7dStreakDays.map((isStreak, index) => ({
      day: orderedDaysOfWeek[index],
      isStreak,
    }));

    // Check if the location state is valid and contains the game data
    useEffect(() => {
        if (!location?.state?.gameState) {
        navigate(PageUrl.Library);
        return;
        }
    }, [location, navigate]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' || event.key === 'ArrowRight') {
                navigate(PageUrl.BookReviewExercises, { state: { gameState: location.state.gameState } });
            } else if (event.key === 'Escape' || event.key === 'ArrowLeft') {
                navigate(PageUrl.Library);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [navigate, location.state.gameState]);

    return (
        <EndGameLayout
            title={getTextLangFrom('EndGameStreakPage-title')}
            isLoading={false}
        >   
            <div className="flex flex-col items-center gap-8">
                <div
                    className="flex font-bold items-center justify-center font-bold pt-1 pr-1 text-4xl w-28 h-28 md:w-32 md:h-32"
                    style={{
                        backgroundImage: `url(${StreakIcon})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                >
                    {user?.numberStreakDays? user.numberStreakDays : 0}
                </div>
                <StreakDays lastWeekDailyStats={lastWeekDailyStats} />
                <div className="flex flex-col gap-4 mt-6 items-center">
                    <h3 className="text-center text-2xl font-medium">{getTextLangFrom('EndGameStreakPage-subtitle')}</h3>
                    <p className="text-center text-text-tertiary">{getTextLangFrom('EndGameStreakPage-text')}</p>
                </div>
            </div>
            <FooterControls>
                <div className="footer-controls-action-div">
                    <Button
                        size="control"
                        trailingIcon={ChevronRightWhite} 
                        onClick={() => navigate(PageUrl.BookReviewExercises, { state: { gameState: location.state.gameState } })}
                        text={getTextLangFrom('word-next')}
                    />
                </div>
            </FooterControls>
            <Fireworks />
        </EndGameLayout>
    );
};

export default EndGameStreakPage;