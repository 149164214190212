import { useCallback, useEffect, useState } from "react";
import { NavbarBackAndGameProgress } from "../../../baseComponents/ProgressBar";
import BookExerciseDiv from "../../../book/BookExerciseDiv";
import { StatsDiv } from "../../../endGame/EndGameStatsPage";
import EndGameLayout from "../../../endGame/EndGameLayout";
import { useLanguage } from "../../../../languages/LanguageContext";
import { ExerciseDiv } from "../../../endGame/EndChapterPage";
import { Card, ExerciseType, Language, Message, PageUrl, Theme } from "../../../../types";
import Button from "../../../baseComponents/Button";
import ChevronRightWhite from '../../../../assets/imagesApp/chevron-right-white.svg';
import fetchDemoMessages from "../useDemoMessages";
import extractRecordsFromChapterMessages from "../../../book/bookUtils/exerciseRecordExtractor";
import Modal from "../../../baseComponents/Modal";
import { useNavigate } from "react-router-dom";
import FooterControls from "../../../appComponents/FooterControls";


const Step1Book: React.FC< { setGameDuration: React.Dispatch<React.SetStateAction<number>>, initialDemoMessages: Message[], setDemoMessages: React.Dispatch<React.SetStateAction<Message[]>>, language: Language } >
= ({ setGameDuration, initialDemoMessages, setDemoMessages, language }) => {
  const [sessionProgress, setSessionProgress] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <div className="relative flex-1 overflow-hidden w-full h-full text-left">
      <div className="flex flex-col h-full overflow-hidden">
        <div className="px-4 pt-6 md:px-4 md:pt-6 md:px-6 md:pt-8">
          <NavbarBackAndGameProgress
            sessionProgress={sessionProgress}
            onBackClick={() => {}}
          />
        </div>
        <BookExerciseDiv
          setSessionProgress={setSessionProgress}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          demoMessages={initialDemoMessages}
          setDemoGameDuration={setGameDuration}
          setDemoMessages={setDemoMessages}
          demoLangTo={language}
        />
      </div>
    </div>
  );
};

const Step2EndGameStats: React.FC< { setDemoStep: React.Dispatch<React.SetStateAction<number>>, accuracy: number, gameDuration: number } >
= ({ setDemoStep, accuracy, gameDuration }) => {
  const { getTextLangFrom } = useLanguage();

  return (
    <div className="flex flex-col w-full h-full justify-between">
      <EndGameLayout title={getTextLangFrom('EndGameStatsPage-title')} isLoading={false} isFullScreen={false}>
        <StatsDiv accuracy={accuracy} gameDuration={gameDuration} />
      </EndGameLayout>
      <FooterControls isRelativePosition={true}>
        <div className="footer-controls-action-div">
          <Button 
            size="control"
            trailingIcon={ChevronRightWhite}
            onClick={() => setDemoStep(3)}
            text={getTextLangFrom('word-continue')}
          />
        </div>
      </FooterControls>
    </div>
  );
};

const Step3EndChapter: React.FC< { handleRestartDemo: () => void, cardsToReview: Card[] } >  
= ({ handleRestartDemo, cardsToReview }) => {
  const { getTextLangFrom } = useLanguage();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    handleRestartDemo();
    setIsModalOpen(false);
  }

  return (
    <div className="flex flex-col w-full h-full justify-between">
      <EndGameLayout title={getTextLangFrom('EndChapterPage-title')} isLoading={false} isFullScreen={false}>
        <ExerciseDiv
          nextExerciseType={cardsToReview.length > 0 ? ExerciseType.BookChapterReview : ExerciseType.BookGeneralReview}
          numberCardsToReview={cardsToReview.length}
        />
      </EndGameLayout>
      <FooterControls isRelativePosition={true}>
        <div className="footer-controls-action-div">
          <Button 
            size="control"
            trailingIcon={ChevronRightWhite}
            onClick={() => setIsModalOpen(true)}
            text={getTextLangFrom('word-continue')}
          />
        </div>
      </FooterControls>
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={getTextLangFrom('MobileDemoDiv-modal-title')}
        onConfirm={() => navigate(PageUrl.SignUp)}
        confirmText={getTextLangFrom('MobileDemoDiv-modal-confirm')}
        onCancel={() => handleCloseModal()}
        cancelText={getTextLangFrom('word-back')}
        onClose={() => handleCloseModal()}
      />
    </div>
  );
};


const BookDemoDiv: React.FC<{ theme: Theme, language: Language }> = ({ theme, language }) => {
  const [cardsToReview, setCardsToReview] = useState<Card[]>([]);
  const [demoStep, setDemoStep] = useState<number>(0);
  const [gameDuration, setGameDuration] = useState<number>(0); 
  const [accuracy, setAccuracy] = useState<number>(0);
  const [demoMessages, setDemoMessages] = useState<Message[]>([]);
  const [initialDemoMessages, setInitialDemoMessages] = useState<Message[]>([]);

  const handleRestartDemo = useCallback(() => {
    setInitialDemoMessages(fetchDemoMessages(language));
    setDemoMessages([]);
    setCardsToReview([]);
    setGameDuration(0);
    setAccuracy(0);
    setDemoStep(0);
  }, [language]);

  // Reset the demo state when the language changes
  useEffect(() => {
    handleRestartDemo();
  }, [handleRestartDemo, language]);

  useEffect(() => {
    // console.log('Demo step:', demoStep);
    if (demoStep === 0) {
      setDemoStep(1);
      // console.log('Demo started');
    } else if(demoStep === 1 && demoMessages.length > 0) {
      const { exerciseRecords, cardsForReviewExercise, accuracy } = extractRecordsFromChapterMessages(demoMessages, language);
      setCardsToReview(cardsForReviewExercise);
      setAccuracy(accuracy);
      setDemoStep(2);
    }
  }, [demoMessages, demoStep]);

  return (
    <div className={`flex flex-1 z-10 shadow-lg h-full w-full rounded-3xl overflow-hidden @container border border-border-secondary bg-opacity-40
        ${theme.includes('dark') ? 'bg-gray-900' : 'bg-white'} `}>
      {demoStep === 1 ? (
       <Step1Book setGameDuration={setGameDuration} initialDemoMessages={initialDemoMessages} setDemoMessages={setDemoMessages} language={language} />
      ) : demoStep === 2 ? ( 
        <Step2EndGameStats setDemoStep={setDemoStep} accuracy={accuracy} gameDuration={gameDuration} />
      ) : (
        <Step3EndChapter handleRestartDemo={handleRestartDemo} cardsToReview={cardsToReview} />
      )}
    </div>
  );
};

export default BookDemoDiv;
