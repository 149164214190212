import { PageUrl, TranslationTooltip } from "../../../types";

const setTooltipPosition = (tooltip: TranslationTooltip, pageUrl: PageUrl, rect?: DOMRect, event?: React.MouseEvent<HTMLSpanElement>) => {
  const maxTooltipWidth = 350;
  const bottomDistanceFromClick = 16;
  const sideDistanceFromClick = 12;

  if (rect) {
    console.log("rect", rect, "scrollX", window.scrollX, "scrollY", window.scrollY);

    if (rect.width === 0 && rect.height === 0) {
      // console.log("rect.width === 0 && rect.height === 0. Returning tooltip with undefined position");

      tooltip.position = { top: undefined, bottom: undefined, left: undefined, right: undefined };
      return tooltip;
    }

    tooltip.position.top = rect.bottom + bottomDistanceFromClick + window.scrollY;

    if (rect.left + maxTooltipWidth > window.innerWidth) {
        tooltip.position.right = window.innerWidth - rect.right + window.scrollX;
    } else {
        tooltip.position.left = rect.left + window.scrollX;
    }
  } else if (event) {
    // console.log("scrollX", window.scrollX, "scrollY", window.scrollY,
    //   "window.innerWidth", window.innerWidth, "window.innerHeight", window.innerHeight,
    //   "event.clientX", event.clientX, "event.clientY", event.clientY);

    if (pageUrl === PageUrl.Website) {
      console.log("Website tooltip position");
      tooltip.position.top =  event.clientY + bottomDistanceFromClick;
      if (event.clientX > window.innerWidth / 2) {
        tooltip.position.right = window.innerWidth - event.clientX + sideDistanceFromClick;
      } else {
        tooltip.position.left = event.clientX + sideDistanceFromClick;
      }
    } else {
      tooltip.position.top = event.clientY + window.scrollY + bottomDistanceFromClick;
    
      // TODO: to improve, evaluate the size of the tooltip and then translate it to the right or left
      if (event.clientX > window.innerWidth / 2) {
        const distanceToLeft = event.clientX;
        const translationToRight = Math.max(maxTooltipWidth - distanceToLeft, 0);
        const clickToTooltip = window.innerWidth - event.clientX + window.scrollX;
        //console.log("Distance to left: ", distanceToLeft, "Translation to right: ", translationToRight, "Click to tooltip: ", clickToTooltip);

        tooltip.position.right = Math.max(sideDistanceFromClick, clickToTooltip - translationToRight);
      } else {
        const distanceToRight = window.innerWidth - event.clientX;
        const translationToLeft = Math.max(maxTooltipWidth - distanceToRight, 0);
        const clickToTooltip = event.clientX + window.scrollX;
        //console.log("Distance to right: ", distanceToRight, "Translation to left: ", translationToLeft, "Click to tooltip: ", clickToTooltip);
        
        tooltip.position.left = Math.max(sideDistanceFromClick, clickToTooltip - translationToLeft);
      }
    }
  }

  // console.log("Tooltip position: ", tooltip.position);

  return tooltip;
}

export default setTooltipPosition;