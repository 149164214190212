import React from 'react';
import Logo from './Logo';
import { PageUrl } from '../../../types';



const Footer: React.FC = () => {
  return (
    <footer className="py-8 layout-padding-x">
      <div className="flex max-w-7xl mx-auto flex-col sm:flex-row gap-4 justify-between md:items-center">
        <Logo />
        <div className="flex gap-4">
          <a href={PageUrl.PrivacyPolicy} className="text-text-tertiary hover:text-text-primary">Privacy Policy</a>
        </div>
        <p className="text-text-tertiary">Made with ❤️ by <a className="text-text-brand hover:text-text-primary font-medium" href="https://www.emilecourthoud.com">Emile Courthoud</a></p>
      </div>
    </footer>
  );
};

export default Footer;
