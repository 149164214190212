import React, { useEffect, useState } from 'react';
import { LanguageProvider } from './languages/LanguageContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './authentification/AuthContext';
// import * as Sentry from "@sentry/react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from './styles/ThemeContext';
import './styles/colors.css';
import './styles/styles.css';
import './styles/stylesApp.css';
import fetchValidClientId from './authentification/userClientId';
import AppRoutes from './routes/routes';

const App: React.FC = () => {
  const [clientId, setClientId] = useState<string | undefined>(undefined);

  // Fetch the client_id from the server
  useEffect(() => {
    const fetchClientId = async () => {
      const client_id = await fetchValidClientId();
      setClientId(client_id);
    };

    fetchClientId();
  }, []);

  return clientId ? (
    <GoogleOAuthProvider clientId={clientId}>
      <AuthProvider>
        <Router>
          <LanguageProvider>
            <ThemeProvider>
              <AppRoutes />
            </ThemeProvider>
          </LanguageProvider>
        </Router>
      </AuthProvider>
    </GoogleOAuthProvider>
  ) : null;
};

export default App;
