import React from 'react';

interface CheckboxProps {
  onChange: () => void;
  checked: boolean;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'secondary';
}

const Checkbox: React.FC<CheckboxProps> = ({ onChange, checked, size = 'sm', variant = 'primary' }) => {
  const sizeClass = size === 'sm' ? 'w-4 h-4' : size === 'md' ? 'w-5 h-5' : 'w-6 h-6';
  const checkmarkSizeClass = size === 'sm' ? 'text-xs' : size === 'md' ? 'text-sm' : 'text-base';

  return (
    <label className="relative inline-flex items-center cursor-pointer w-max">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className={`${sizeClass} appearance-none border border-border-primary rounded-md cursor-pointer transition duration-200 ${variant === 'primary' ? 'bg-bg-transparent' : 'bg-bg-secondary'}
          checked:bg-buttonPrimary-bg checked:border-buttonPrimary-border focus:outline-none focus:shadow-ring-brand-xs`}
        onClick={(event) => event.stopPropagation()}
      />
      {checked && (
        <span
          className={`absolute ${checkmarkSizeClass} text-buttonPrimary-fg pointer-events-none leading-none`}
          style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        >
          ✓
        </span>
      )}
    </label>
  );
};

export default Checkbox;
