import React, { useState, useEffect, useRef } from "react";
import OptionsIcon from '../../assets/imagesApp/dots-vertical.svg';
import Button from "../baseComponents/Button";

type DropDownOptionProps = {
  options: string[];
  optionSelection: (option: string) => void;
  toggleDropDown: () => void;
};

const DropDownOption: React.FC<DropDownOptionProps> = ({
  options,
  optionSelection,
  toggleDropDown,
}: DropDownOptionProps): JSX.Element => {

  const onClickHandler = (option: string): void => {
    optionSelection(option);
    toggleDropDown(); // Close the dropdown after selection
  };

  return (
    <div className={`absolute z-10 top-12 right-0 border border-border-secondary bg-bg-primary p-1 text-left rounded-lg pointer-events-auto`}>
      {options.map((option: string, index: number): JSX.Element => {
        return (
          <Button
            variant="tertiary"
            size="sm"
            className={`w-full text-left`}
            text={option}
            onClick={() => onClickHandler(option)}
            key={index}
            isJustifyStart={true}
          />
        );
      })}
    </div>
  );
};

interface DropDownCustomProps {
  options: string[];
  setSelectedOption: (option: string) => void;
  id?: number;
  setSelectedId?: (id: number) => void;
}

const DropDownCustom: React.FC<DropDownCustomProps> = ({ options, setSelectedOption, id, setSelectedId }): JSX.Element => {
  const [showdropdown, setShowDropDown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null); // Ref for the dropdown element

  const toggleDropDown = () => {
    setShowDropDown(!showdropdown);
  };

  const optionSelection = (option: string): void => {
    setSelectedOption(option);
    setSelectedId && id && setSelectedId(id);
    setShowDropDown(false); // Hide dropdown after option selection
  };

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div ref={dropdownRef} className="relative">
      <Button
        variant="tertiary"
        iconOnly={true}
        trailingIcon={OptionsIcon}
        className={showdropdown ? "active" : undefined}
        onClick={toggleDropDown}
      />
      {showdropdown && (
        <DropDownOption
          options={options}
          toggleDropDown={toggleDropDown}
          optionSelection={optionSelection}
        />
      )}
    </div>
  );
};

export default DropDownCustom;
