import React from 'react';
import ChevronDown from '../../assets/images/chevron-down.svg';

interface DropDownProps {
  value?: string | { id: number; label: string };
  onChange: (value: string | { id: number; label: string }) => void;
  options: string[] | { id: number; label: string }[];
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  placeholder?: string;
}

export const DropDownButton: React.FC<DropDownProps> = ({
  value,
  onChange,
  options,
  disabled,
  size = 'md',
  placeholder,
}) => {
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    const selectedOption = (options as Array<string | { id: number; label: string }>).find(
      (option) =>
        typeof option === 'object' ? option.label === selectedValue : option === selectedValue
    );
    onChange(selectedOption || selectedValue);
  };

  const sizeClasses = {
    sm: 'px-3 py-2 text-sm',
    md: 'px-4 py-2 text-base',
    lg: 'px-6 py-3 text-lg',
  };

  return (
    <div className="relative w-full">
      <select
        value={value ? (typeof value === 'object' ? value.label : value) : ''}
        onChange={handleSelectChange}
        disabled={disabled}
        className={`w-full pr-12 border rounded-lg font-medium bg-bg-primary text-text-primary border-border-primary shadow-xs 
          focus:border-border-brand focus:outline-none focus:shadow-ring-brand-xs appearance-none
          ${sizeClasses[size]} 
          ${disabled ? 'text-text-disabled cursor-not-allowed' : ''}
          ${!value ? 'text-text-tertiary font-normal' : 'text-text-primary'}  /* Apply gray text if no value is selected */
        `}
        style={{
          backgroundImage: `url(${ChevronDown})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right 14px center',
        }}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option
            key={typeof option === 'object' ? option.id : option}
            value={typeof option === 'object' ? option.label : option}
          >
            {typeof option === 'object' ? option.label : option}
          </option>
        ))}
      </select>
    </div>
  );
};


interface DropDownFieldProps {
  value?: string | { id: number; label: string };
  onChange: (value: string | { id: number; label: string }) => void;
  options: string[] | { id: number; label: string }[];
  disabled?: boolean;
  label?: string;
  size?: 'sm' | 'md' | 'lg';
  placeholder?: string;
  className?: string;
}

export const DropDownField: React.FC<DropDownFieldProps> = ({
  value,
  onChange,
  options,
  disabled,
  label,
  size = 'md',
  placeholder,
  className = ''
}) => {
  return (
    <div className={`form-div ${className}`}>
      {label && <label className="form-label">{label}</label>}
      <DropDownButton
        value={value}
        onChange={onChange}
        options={options}
        disabled={disabled}
        size={size}
        placeholder={placeholder}
      />
    </div>
  );
};


interface DropDownOptionsProps {
  options: string[];
}


export const DropDownOptions: React.FC<DropDownOptionsProps> = ({ options }) => {
  return (
    <>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </>
  );
};
