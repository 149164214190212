import React from 'react';
import { useLanguage } from '../../languages/LanguageContext';
import OnboardingMultipleChoiceTemplate from './OnboardingMultipleChoiceTemplate';
import { OnboardingOption } from '../../types';


const onboardingOptions: OnboardingOption[] = [
  { code: 'de', text: 'German', icon: '🇩🇪' },
  { code: 'it', text: 'Italian', icon: '🇮🇹' },
  { code: 'fr', text: 'French', icon: '🇫🇷' },
  { code: 'es', text: 'Spanish', icon: '🇪🇸' },
  // { code: 'en', text: 'English', icon: '🇬🇧' },
];


const OnboardingLanguage: React.FC = () => {
  const { getTextLangFrom } = useLanguage();

  return (
    OnboardingMultipleChoiceTemplate({
      step: 2,
      title: getTextLangFrom('OnboardingPage-language-title'),
      onboardingOptions: onboardingOptions
    })
  );
};

export default OnboardingLanguage;