import { useLanguage } from '../../../languages/LanguageContext';
import Modal from '../../baseComponents/Modal';

interface ModalInterruptGameProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel: () => void;
}

const ModalInterruptGame: React.FC<ModalInterruptGameProps> = ({ isOpen, setIsOpen, onCancel }) => {
  const { getTextLangFrom } = useLanguage();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={getTextLangFrom('modal-interrupt-header')}
      message={getTextLangFrom('modal-interrupt-game-description')}
      confirmText={getTextLangFrom('word-resume')}
      cancelText={getTextLangFrom('word-leave')}
      onConfirm={() => setIsOpen(false)}
      onCancel={() => onCancel()}
    />
  );
};

export default ModalInterruptGame;
