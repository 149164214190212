import React from 'react';
import { Section, SectionContainer } from '../WebsiteBaseComponents/Layout';
import { Heading } from '../WebsiteBaseComponents/Layout';
import BooksImage from '../../../assets/imagesWebsite/books.svg';
import MultipleChoiceImage from '../../../assets/imagesApp/home-multiple-choice.svg';


interface FeatureContainerProps {
  tag?: string;
  title: string;
  description: string;
  benefits?: string[];
  image?: string;
  position?: 'center' | 'left' | 'right';
}

const FeatureContainer: React.FC<FeatureContainerProps> = ({
  tag,
  title,
  description,
  benefits,
  image,
  position,
}) => {
  return (
    <div className="flex flex-col lg:flex-row items-center lg:gap-10 lg:gap-24 w-full justify-center">
      {/* Text section: Centered on small screens, left-aligned on large screens */}
      <div className={`flex flex-col ${position === 'left' && 'lg:order-1'} text-left gap-4`}>
        <Heading
          tag={tag}
          title={title}
          description={description}
          position={position}
        />
        {benefits && (
          <ul className="space-y-4 mt-8">
            {benefits.map((benefit, index) => (
              <li key={index} className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-7 w-7 text-text-brand mr-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <p className="w-full text-text-primary">{benefit}</p>
              </li>
            ))}
          </ul>
        )}  
      </div>

      { image && (
        <div className={`flex lg:w-1/2 mt-8 lg:mt-0 justify-center ${position === 'right' ? 'lg:order-1' : ''}`}>
          <img src={image} alt="Feature" />
        </div>
      )}
    </div>
  );
};



const Features: React.FC = () => {
  return (
    <Section>
      <SectionContainer className='gap-24'>
        <FeatureContainer
          tag='Features'
          title='Learning languages can be fun'
          description='Select or upload your favorite book and start learning a new language!'
          position="center"
        />
        <FeatureContainer
          tag='Any book, any language'
          title='Read the book you love.'
          description='Reading books in a new language can be challenging, but Booklet makes it easy and fun.'
          benefits={[
            'Choose the language you want to learn',
            "Upload your favorite book or choose from Booklet's extensive library",
            'Currently Booklet supports Spanish 🇪🇸, French 🇫🇷, German 🇩🇪, and Italian 🇮🇹',
          ]}
          image={BooksImage}
          position="left"
        />
        <FeatureContainer
          tag='Read. Understand. Practice.'
          title='Practice and have fun!'
          description='Just reading is not enough. Practice vocabulary as you read or play language games.'
          benefits={[
            'Click on any word or sentence to see the translation',
            'Save the words you want to learn',
            'Practice vocabulary while reading and playing games',
          ]}
          image={MultipleChoiceImage}
          position="right"
        />
        <FeatureContainer
          tag='Web, Android, iOS'
          title='Available on mobile and web'
          description='Read on the way home or from your sofa! Access Booklet from this website and start learning'
          position='center'
        />
      </SectionContainer>
    </Section>
  );
};

export default Features;