import { BookExercise, CefrLevel, ExerciseState, Message, TooltipTag } from '../../../types';

function selectWordLangToIdsForExercises(parts: BookExercise[], userCefrLevel: CefrLevel): number[] {
  const ids: Record<string, number[]> = {
    "1_hard": [],
    "2_new_same_cefr": [],
    "3_to_review": [],
    "4_new_close_cefr": [],
    "5_mastered": []
  };

  for (const part of parts) {
    const cefrDistance = part.cefrLevel !== undefined 
      ? getDistanceBetweenCefrLevels(part.cefrLevel, userCefrLevel)
      : null;

    // console.log('cefrDistance', cefrDistance, 'part.cefrLevel', part.cefrLevel, 'userCefrLevel', userCefrLevel);

    if (part.netCorrectScore !== undefined && part.netCorrectScore < 0) {
      ids["1_hard"].push(part.wordLangToId);
    } else if (!part.lastSeen && part.cefrLevel !== undefined && cefrDistance !== null && cefrDistance <= 0) {
      ids["2_new_same_cefr"].push(part.wordLangToId);
    } else if (part.netCorrectScore !== undefined && part.netCorrectScore >= 0 && part.netCorrectScore < 1) {
      ids["3_to_review"].push(part.wordLangToId);
    } else if (!part.lastSeen && part.cefrLevel !== undefined && cefrDistance !== null && cefrDistance <= 2) {
      ids["4_new_close_cefr"].push(part.wordLangToId);
    } else if (part.netCorrectScore !== undefined && part.netCorrectScore >= 1) {
      ids["5_mastered"].push(part.wordLangToId);
    }
  }

  // Shuffle the new and close CEFR level exercises
  shuffleIds(ids["2_new_same_cefr"]);
  shuffleIds(ids["4_new_close_cefr"]);

  // Gather the exercise IDs in priority order
  let wordIdsForExercises = [
    ...ids["1_hard"],
    ...ids["2_new_same_cefr"],
    ...ids["3_to_review"],
    ...ids["4_new_close_cefr"],
    ...ids["5_mastered"]
  ];

  // console.log('1_hard', ids["1_hard"]);
  // console.log('2_new_same_cefr', ids["2_new_same_cefr"]);
  // console.log('3_to_review', ids["3_to_review"]);
  // console.log('4_new_close_cefr', ids["4_new_close_cefr"]);
  // console.log('5_mastered', ids["5_mastered"]);

  // Remove duplicates while preserving order
  wordIdsForExercises = Array.from(new Set(wordIdsForExercises));

  wordIdsForExercises = wordIdsForExercises.slice(0, 3);
  
  // console.log('wordLangToIdsForExercises', wordIdsForExercises);

  return wordIdsForExercises;
}

// Utility function to shuffle an array in place
function shuffleIds(array: number[]): void {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

const mapCefrLevelsToIndex: { [key in CefrLevel]: number } = {
  A1: 0,
  A2: 1,
  B1: 2,
  B2: 3,
  C1: 4,
  C2: 5,
};

function getDistanceBetweenCefrLevels(subtrahendLevel: CefrLevel | null, minuendLevel: CefrLevel | null): number | null {
  if (subtrahendLevel === null || minuendLevel === null) {
    return null;
  } else {
    return mapCefrLevelsToIndex[subtrahendLevel] - mapCefrLevelsToIndex[minuendLevel];
  }
}


function getTooltipTag(bookExercisePart: BookExercise): TooltipTag {
  // console.log('bookExercisePart', bookExercisePart);

  if (!bookExercisePart.netCorrectScore) {
    return TooltipTag.New;
  } else if (bookExercisePart.isInBlackList) {
    return TooltipTag.Blacklisted;
  } else if (bookExercisePart.netCorrectScore < 0) {
    return TooltipTag.Hard;
  } else if (bookExercisePart.netCorrectScore > 1) {
    return TooltipTag.Mastered;
  } else {
    return TooltipTag.NoTag;
  }
}

function extractMessageFromText(text: string, uniqueBookExercises: BookExercise[], userCefrLevel: CefrLevel): Message {
  // console.log('uniqueBookExercises', uniqueBookExercises, 'userCefrLevel', userCefrLevel, 'text', text);
  const regex = /\{\{(\d+)>([^}]+)\}\}/g;
  let regexMatch: RegExpExecArray | null;
  const bookExercises: (string | BookExercise)[] = [];
  let lastIndex = 0;

  const currentBookExercises: BookExercise[] = [];

  while ((regexMatch = regex.exec(text)) !== null) { 
    const [, wordLangToIdStr] = regexMatch;
    currentBookExercises.push(...uniqueBookExercises.filter(exercise => exercise.wordLangToId === parseInt(wordLangToIdStr)));
  }
  

  let wordLangToIdsForExercises = selectWordLangToIdsForExercises(currentBookExercises, userCefrLevel);


  while ((regexMatch = regex.exec(text)) !== null) {
    const index = regexMatch.index;

    const [matchedText, wordLangToIdStr, solution] = regexMatch;

    if (index > lastIndex) {
      bookExercises.push(text.substring(lastIndex, index));
    } 

    const wordLangToId = Number(wordLangToIdStr);
    const isExercise = wordLangToIdsForExercises.includes(wordLangToId);

    if (isExercise) {
      // Remove the wordLangToId from the list of exercises to avoid duplicates
      wordLangToIdsForExercises = wordLangToIdsForExercises.filter(id => id !== wordLangToId);
    }

    // console.log('wordLangToId', wordLangToId, 'isExercise', isExercise);

    const currentBookExercise = currentBookExercises.find(part => part.wordLangToId === wordLangToId) || {} as BookExercise;

    // console.log('currentBookExercise', currentBookExercise, 'parts', parts);

    bookExercises.push({
      ...currentBookExercise,
      exerciseState: ExerciseState.Unanswered,
      isExercise: isExercise,
      tag: getTooltipTag(currentBookExercise as BookExercise),
      solution: solution,
    } as BookExercise);

    lastIndex = index + (matchedText as string).length 
  }

  if (lastIndex < text.length) {
    bookExercises.push(text.substring(lastIndex));
  }

  return {
    messageRole: 'book-translate',
    text: text,
    bookExercises: bookExercises,
  } as Message;
}

function createMessagesWithBookExercises(chapter_text: string, bookExercises: BookExercise[], userCefrLevel: CefrLevel): Message[] {
  const paragraphs = chapter_text.split('\n');
  // console.log('paragraphs', paragraphs, 'bookExercises', bookExercises, 'userCefrLevel', userCefrLevel);
  return paragraphs.map(paragraph =>
    extractMessageFromText(paragraph, bookExercises, userCefrLevel)
  );
};

export default createMessagesWithBookExercises;

