import { useCallback, useEffect, useRef, useState } from 'react';
import { saveTranslationPreferences } from '../../../api/Messages';
import { PageUrl, TranslationTooltip } from '../../../types';
import Tag from '../../baseComponents/Tag';
import NewWordIcon from '../../../assets/imagesApp/new-word.svg';
import HardWordIcon from '../../../assets/imagesApp/hard-word.svg';
import ReviewWordIcon from '../../../assets/imagesApp/mastered-word.svg';
import BlackListedIcon from '../../../assets/imagesApp/blacklisted-word.svg';
import Checkbox from '../../baseComponents/Checkbox';
import { useLanguage } from '../../../languages/LanguageContext';
import Toast from '../../baseComponents/Toast';
import { styled } from 'styled-components';
import { Loader } from '../../baseComponents/Loader';
import TranslationsDetailsDiv from '../TranslationDetails';

const StyledTooltip = styled.div<{ tooltip: TranslationTooltip }>`
  position: fixed;
  top: ${({ tooltip }) => tooltip.position.top ? `${tooltip.position.top}px` : 'auto'};
  bottom: ${({ tooltip }) => tooltip.position.bottom ? `${tooltip.position.bottom}px` : 'auto'};
  left: ${({ tooltip }) => tooltip.position.left ? `${tooltip.position.left}px` : 'auto'};
  right: ${({ tooltip }) => tooltip.position.right ? `${tooltip.position.right}px` : 'auto'};
`;


interface TranslationTooltipDivProps {
  tooltip: TranslationTooltip;
  setTooltip: React.Dispatch<React.SetStateAction<TranslationTooltip>>;
  learnWordPreferences: { translationId: number, isToLearn: boolean }[];
  setLearnWordPreferences: (learnWordPreferences: { translationId: number, isToLearn: boolean }[]) => void;
  pageUrl: PageUrl;
}

const TranslationTooltipDiv = ({ tooltip, setTooltip, learnWordPreferences, setLearnWordPreferences, pageUrl }: TranslationTooltipDivProps) => {
  const [showToast, setShowToast] = useState<boolean>(false);
  const { getTextLangFrom } = useLanguage();
  const [isChecked, setIsChecked] = useState<boolean | undefined>(undefined);
  const [isCheckboxClickable, setIsCheckboxClickable] = useState<boolean>(true);
  const [tagText, setTagText] = useState<string | undefined>(undefined);
  const [tagIcon, setTagIcon] = useState<string | undefined>(undefined);
  const originalTag = tooltip.bookExercise?.tag || "noTag";
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const updateTag = (tag: string, isToLearn: boolean, isInBlackList: boolean) => {
    // isBlackListed is required since  the book exercise tags are not updated, while learnWordPreferences are
    // console.log("updateTag", tag, "isToLearn", isToLearn, "isInBlackList", isInBlackList);
    
    if ((tag === "blacklistedWord" || isInBlackList) && !isToLearn) {
      setTagText("Blacklisted");
      setTagIcon(BlackListedIcon);
    } else if (tag === "noTag") {
      setTagText(undefined);
      setTagIcon(undefined);
    } else if (tag === "newWord" || (tag === "blacklistedWord" && isToLearn)) {
      setTagText("New word");
      setTagIcon(NewWordIcon);
    } else if (tag === "hardWord") {
      setTagText("Hard word");
      setTagIcon(HardWordIcon);
    } else if (tag === "masteredWord") {
      setTagText("Mastered word");
      setTagIcon(ReviewWordIcon);
    }
  }

  const getLearnWordPreference = useCallback(() => {
    const learnWordPreference = learnWordPreferences.find(
      (learnWordPreference: any) => learnWordPreference.translationId === tooltip.bookExercise?.translationId
    );
    if (learnWordPreference) {
      return { isToLearn: learnWordPreference.isToLearn, isInBlackList: !learnWordPreference.isToLearn };
    } else {
      return { isToLearn: tooltip.bookExercise?.netCorrectScore !== undefined  && tooltip.bookExercise?.tag !== "blacklistedWord", isInBlackList: false };
    }
  }, [learnWordPreferences, tooltip]);

  // Update the tag and isChecked state when the tooltip is visible
  useEffect(() => {
    if (tooltip.isVisible && tooltip.bookExercise?.translationId !== undefined && isChecked === undefined) {
      const { isToLearn, isInBlackList } = getLearnWordPreference();
      updateTag(tooltip.bookExercise.tag || "noTag", isToLearn || false, isInBlackList);
      setIsChecked(isToLearn);
    } else if (!tooltip.isVisible) {
      setIsChecked(undefined);
      setShowToast(false);
    }
  }, [tooltip, isChecked, getLearnWordPreference]);


  // Close the streak tile if clicking outside of it or the button
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        setTooltip({ ...tooltip, isVisible: false });
      }
    };

    if (tooltip.isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tooltip, setTooltip]);

  const onCheckboxClick = async () => {
    if (tooltip.bookExercise?.translationId && isCheckboxClickable) {
      // Prevent multiple clicks before the uku preferences are saved
      setIsCheckboxClickable(false);
      
      let isToLearn = !isChecked;

      setIsChecked(isToLearn);
      updateTag(originalTag, isToLearn, !isToLearn);

      let newLearnWordPreferences = learnWordPreferences.filter(
        (learnWordPreference: any) => learnWordPreference.translationId !== tooltip.bookExercise?.translationId
      );
      newLearnWordPreferences.push({ translationId: tooltip.bookExercise.translationId, isToLearn: isToLearn });
      setLearnWordPreferences(newLearnWordPreferences);

      if (pageUrl !== PageUrl.Website) {
        await saveTranslationPreferences(tooltip.userId, tooltip.bookExercise.translationId, tooltip.chatId, isToLearn);
      }

      setShowToast(true);
      setIsCheckboxClickable(true);
    } else {
      console.error("Word translation id is not defined");
    }
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  // Tooltip is destroyed when not visible
  if (!tooltip.isVisible) {
    return null;
  }

  return (
    <StyledTooltip tooltip={tooltip}
      className={`absolute bg-tooltip-bg py-2 rounded-md z-50 max-w-[350px] flex flex-col shadow-md border border-tooltip-border`}
      onMouseUp={handleMouseUp}
      ref={tooltipRef}
    >
      {tooltip.isLoading ? (
        <div className="px-2"><Loader /></div>
      ) : (
        <>
          <div className="flex flex-col gap-1 px-2">
            {(tooltip.bookExercise?.translationId && tagText) && <Tag text={tagText} icon={tagIcon} variant='secondary' className='border-border-primary' />}
            <TranslationsDetailsDiv
              bookExercise={tooltip.bookExercise}
              googleTranslation={tooltip.googleTranslation}
              size="small"
              errorMessage={tooltip.errorMessage}
            />
          </div>
          {tooltip.bookExercise?.translationId && (
            <div className="border-t border-border-primary mt-1">
              <div className="flex gap-2 px-2 pt-2 items-center text-sm">
                {isChecked !== undefined && <Checkbox onChange={onCheckboxClick} checked={isChecked} size='md' variant='secondary' />}
                {getTextLangFrom('tooltip-want-to-learning')}
              </div>
            </div>
          )}
        </>
      )}
      <Toast
        title={isChecked ? getTextLangFrom('tooltip-toast-add-word-title') : getTextLangFrom('tooltip-toast-remove-word-title')}
        subtitle={isChecked ? getTextLangFrom('tooltip-toast-add-word-text') : getTextLangFrom('tooltip-toast-remove-word-text')}
        buttonText={getTextLangFrom('word-hide')}
        onButtonClick={() => setShowToast(false)}
        showToast={showToast}
        autoDismissTimeout={3000}
        setShowToast={setShowToast}
      />
    </StyledTooltip>
  );
};

export default TranslationTooltipDiv;