import React, { useState } from 'react';
import Logo from './Logo';
import { useNavigate } from 'react-router-dom';
import Button from '../../baseComponents/Button';
import MenuIcon from '../../../assets/imagesWebsite/menu-03.svg';
import CloseIcon from '../../../assets/images/x-close.svg';
import { PageUrl } from '../../../types';

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <nav className={`${isMobileMenuOpen && 'bg-bg-secondary sm:bg-bg-primary'}`}>
      <div className="max-w-7xl mx-auto layout-padding-x">
        <div className="flex justify-between items-center h-16">
          <Logo />
          <div className="hidden sm:flex items-center space-x-4">
            <Button text="Log In" variant="tertiary" onClick={() => navigate(PageUrl.Library)} />
            <Button text="Get Booklet free" onClick={() => navigate(PageUrl.SignUp)} />
          </div>
          {/* Mobile menu toggle button */}
          <div className="flex sm:hidden">
            <Button
              variant="tertiary"
              iconOnly
              leadingIcon={isMobileMenuOpen ? CloseIcon : MenuIcon}
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            />
          </div>
        </div>
      </div>
      {/* Mobile menu dropdown with transition */}
      <div
        className={`sm:hidden bg-bg-secondary overflow-hidden shadow-lg ${
          isMobileMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
        } transition-all duration-300 ease-in-out`}
      >
        {isMobileMenuOpen && (
          <div className="flex flex-col items-center space-y-4 py-4">
            <Button text="Log In" variant="tertiary" onClick={() => navigate(PageUrl.Library)} />
            <Button text="Get Booklet free" onClick={() => navigate(PageUrl.SignUp)} />
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
