import { User, UserStatus } from '../types';
import { apiUrl, fetchData } from './api';


export async function getUserByEmail(email: string): Promise<User> {
  return await fetchData<User>(`${apiUrl}/user`, 'POST', { email });
}

export async function updateUser(user: User): Promise<boolean> {
  return await fetchData<boolean>(`${apiUrl}/user/update`, 'POST', user);
}

export async function checkUserStatusByEmail(email: string): Promise<UserStatus> {
  return await fetchData<UserStatus>(`${apiUrl}/user/exists`, 'POST', { email });
}
