import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import enTranslations from './locales/en.json';
import deTranslations from './locales/de.json';
import itTranslations from './locales/it.json';
import frTranslations from './locales/fr.json';
import esTranslations from './locales/es.json';
import { useAuth } from '../authentification/AuthContext'; // Adjust path as per your structure
import { Language } from '../types';

export const translations: { [key in Language]: { [key: string]: string } } = {
  en: enTranslations,
  de: deTranslations,
  it: itTranslations,
  fr: frTranslations,
  es: esTranslations,
};

interface LanguageContextProps {
  langFrom: Language;
  langTo: Language;
  setLangFrom: (language: Language) => void;
  setLangTo: (language: Language) => void;
  getTextLangFrom: (key: string) => string;
  getTextLangTo: (key: string) => string;
}

const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useAuth(); // Assuming useAuth provides user information including language settings
  const [langFrom, setLangFrom] = useState<Language>(user?.langFrom || Language.English);
  const [langTo, setLangTo] = useState<Language>(user?.langTo || Language.German);

  useEffect(() => {
    // Set initial languages based on user's preferences
    if (user?.langFrom && user?.langTo) {
      setLangFrom(user.langFrom);
      setLangTo(user.langTo);
    } else {
      // Fallback to default languages if user settings are not available
      setLangFrom(Language.English);
      setLangTo(Language.German);
    }
  }, [user]);

  const getTextLangFrom = (key: string) => {
    return translations[langFrom][key] || key;
  };

  const getTextLangTo = (key: string) => {
    return translations[langTo][key] || key;
  };

  const contextValue: LanguageContextProps = {
    langFrom,
    langTo,
    setLangFrom,
    setLangTo,
    getTextLangFrom,
    getTextLangTo,
  };

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
