import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DailyStreakTile } from '../profile/StatsTiles';
import { useAuth } from '../../authentification/AuthContext';
import StreakIconActive from '../../assets/images/streakSmallIconActive.svg';
import StreakIconLastDay from '../../assets/images/streakSmallIconLastDay.svg';
import StreakIconInactive from '../../assets/images/streakSmallIconInactive.svg';

const StreakInfo: React.FC = () => {
  const tileRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isStreakInfoOpen, setIsStreakInfoOpen] = useState(false);
  const { user } = useAuth();

  const onClose = useCallback(() => {
    setIsStreakInfoOpen(false);
  }, []);

  const StreakIcon = 
    user?.last7dStreakDays?.[6] ? StreakIconActive : 
    (user?.last7dStreakDays?.[5] && !user?.last7dStreakDays?.[6]) ? StreakIconLastDay : 
    StreakIconInactive;

  // Close the streak tile if clicking outside of it or the button
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tileRef.current &&
        !tileRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isStreakInfoOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isStreakInfoOpen, onClose]);

  // Handle button click to toggle the streak info
  const handleButtonClick = () => {
    setIsStreakInfoOpen((prev) => !prev); // Toggle the dropdown
  };

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        className={`h-9 flex items-center gap-2 text-lg font-semibold
            ${isStreakInfoOpen && 'bg-active-color'}
            ${!user?.last7dStreakDays?.[6] && 'text-text-tertiary'}
          `}
        onClick={handleButtonClick} // Only use onClick for toggling
      >
        <img src={StreakIcon} alt="Streak" className='h-8'/>
        {user?.numberStreakDays ? user.numberStreakDays : 0}
      </button>
      {isStreakInfoOpen && (
        <div
          ref={tileRef}
          className="absolute right-0 mt-2 bg-bg-primary shadow-lg rounded-lg z-50"
          style={{ width: 'min(90vw, 400px)' }}
        >
          <DailyStreakTile />
        </div>
      )}
    </div>
  );
};

export default StreakInfo;
