import React, { useEffect, useRef } from 'react';

const Fireworks: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (!canvas || !context) return;

    // TODO: Adjust colors using themes
    const colors = [
      '#227C9D',
      '#1DA0A8',
      '#17C3B2',
      '#FFCB77',
      '#FFE2B3',
      '#FEF9EF',
      '#FEB3B1',
      '#FE6D73'
    ];

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const fireworks: Firework[] = [];
    let timer: number | undefined;

    // Create initial fireworks concentrated at 3/4 height of the screen
    const createInitialFireworks = async () => {
      const centerX = canvas.width / 2;
      const centerY = canvas.height * 1.3 / 4;
      const radius = Math.min(canvas.width, canvas.height) * 0.15; // Define the radius of the central area

      for (let i = 0; i < 5; i++) {
        const angle = Math.random() * 2 * Math.PI;
        const distance = Math.random() * radius;
        const x = centerX + distance * Math.cos(angle);
        const y = centerY + distance * Math.sin(angle);
        fireworks.push(new Firework(x, y, colors));
        await new Promise(resolve => setTimeout(resolve, 20)); // 20ms delay
      }
    };
    createInitialFireworks();

    const updateFireworks = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      fireworks.forEach((firework, index) => {
        firework.update();
        firework.draw(context);
        if (firework.done) {
          fireworks.splice(index, 1);
        }
      });
    };

    const animate = () => {
      updateFireworks();
      timer = requestAnimationFrame(animate);
    };

    timer = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(timer!);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{ position: 'fixed', top: 0, left: 0, zIndex: 9999, pointerEvents: 'none' }}
    />
  );
};

class Firework {
  x: number;
  y: number;
  done: boolean;
  particles: Particle[];
  colors: string[];

  constructor(x: number, y: number, colors: string[]) {
    this.x = x;
    this.y = y;
    this.done = false;
    this.particles = [];
    this.colors = colors;
    this.createParticles();
  }

  createParticles() {
    const particleCount = 50; // Adjusted for performance
    for (let i = 0; i < particleCount; i++) {
      this.particles.push(new Particle(this.x, this.y, this.colors));
    }
  }

  update() {
    // Remove particles that have faded out
    this.particles = this.particles.filter((particle) => particle.alpha > 0.01); // Adjusted threshold
    // Update remaining particles
    this.particles.forEach((particle) => particle.update());
    // Check if any particles remain
    this.done = this.particles.length === 0;
  }

  draw(context: CanvasRenderingContext2D) {
    this.particles.forEach((particle) => particle.draw(context));
  }
}

class Particle {
  x: number;
  y: number;
  vx: number;
  vy: number;
  alpha: number;
  size: number;
  color: string;

  constructor(x: number, y: number, colors: string[]) {
    this.x = x;
    this.y = y;
    this.vx = (Math.random() - 0.5) * (Math.random() * 6); // Random value between -3 and 3
    this.vy = (Math.random() - 0.5) * (Math.random() * 6); // Random value between -3 and 3
    this.alpha = 1;
    this.size = Math.random() * 3 + 0.3; // Random size between 0.3 and 3.3
    this.color = colors[Math.floor(Math.random() * colors.length)];
  }

  update() {
    this.x += this.vx;
    this.y += this.vy;
    this.alpha -= 0.01; // Adjusted for smoother fade out
  }

  draw(context: CanvasRenderingContext2D) {
    context.save();
    context.globalAlpha = this.alpha;
    context.beginPath();
    context.arc(this.x, this.y, this.size, 0, Math.PI * 2);
    context.fillStyle = this.color;
    context.fill();
    context.restore();
  }
}

export default Fireworks;
