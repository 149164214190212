// useKeyboardControls.ts

import { useEffect } from 'react';

const useKeyboardControls = (showAnswerButtons: boolean, handleRevealAnswer: () => void, handleNext: () => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (showAnswerButtons && (event.key === 'Enter' || event.key === 'ArrowDown' || event.key === 'ArrowRight')) {
        event.preventDefault();
        handleNext();
      } else if (event.key === 'Enter' || event.key === 'ArrowDown' || event.key === 'ArrowRight') {
        event.preventDefault();
        handleRevealAnswer();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [showAnswerButtons, handleRevealAnswer, handleNext]);
};

export default useKeyboardControls;
