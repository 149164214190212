import { ExerciseState, GameMode } from '../types';


// Normalize text for comparison by removing case differences, accents, and certain substitutions.
function normalizeTextForComparison(text: string): string {
    // Convert to lowercase
    text = text.toLowerCase();
    
    // Remove initial particles, such as "zu " in the beginning
    text = removeInitialParticle(text);

    // Replace "ß" with "ss"
    text = text.replace("ß", "ss");

    // Remove accents
    text = removeAccents(text);

    return text;
}

// Remove accents from a string
function removeAccents(text: string): string {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

// Remove specific initial particles (like "zu ") from a string
function removeInitialParticle(text: string): string {
    const particles = ["zu"]; // Add any other particles as needed
    for (const particle of particles) {
        const particleWithSpace = particle + " ";
        if (text.startsWith(particleWithSpace)) {
            return text.slice(particleWithSpace.length);
        }
    }
    return text;
}

// Main function to evaluate the exercise state
function evaluateExerciseState(
    userResponse: string,
    solution: string,
    gameMode?: GameMode,
): ExerciseState {
    if (userResponse.trim() === solution) {
        return ExerciseState.Correct;
    } else if (!gameMode || gameMode !== GameMode.Write) {
        return ExerciseState.Wrong;
    } else {
        const normalizeduserResponse = normalizeTextForComparison(userResponse);
        const normalizedSolution = normalizeTextForComparison(solution);

        if (normalizeduserResponse === normalizedSolution) {
            return ExerciseState.AlmostCorrect;
        } else {
            return ExerciseState.Wrong;
        }
    }
}

export default evaluateExerciseState;
