import React, { useEffect, useRef, useState } from 'react';
import { Section, SectionContainer } from '../WebsiteBaseComponents/Layout';
import Button from '../../baseComponents/Button';
import { useNavigate } from 'react-router-dom';
import { PageUrl } from '../../../types';
import CelebratingBotImage from '../../../assets/imagesApp/robot-celebrating-success.svg';
import Fireworks from '../../baseComponents/FireworksAnimation';

const CallToAction: React.FC = () => {
  const navigate = useNavigate();
  const sectionRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // Uses the viewport as the root
        rootMargin: "0px 0px -70% 0px", // Adjusts the bottom margin to trigger at 70% of the viewport
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <Section >
      <SectionContainer>
        <div className="flex flex-col sm:flex-row text-center max-w-[1216px] mx-auto bg-bg-tertiary rounded-xl sm:rounded-[24px]" ref={sectionRef}>
          <div className="flex flex-col items-start text-left p-8 sm:p-8 md:p-16 justify-center max-w-[480px] flex-1">
            <h3 className="text-2xl sm:text-3xl font-semibold mb-5">Start reading, it’s free!</h3>
            <p className="text-lg mb-12 text-text-secondary">
              Completely free. No payments, no hidden fees. Just sometimes you'll be asked if you want to offer the developer a cup of coffee ☕
            </p>
            <Button text="Get Started for free" onClick={() => navigate(PageUrl.SignUp)} />
          </div>
            <div className="flex hidden md:block flex-1 self-end">
            <img src={CelebratingBotImage} alt="Celebrating Bot" className="scale-x-[-1] w-80 h-80" />
            </div>
        </div>
      </SectionContainer>
      {isVisible && <Fireworks />}
    </Section>
  );
};

export default CallToAction;
