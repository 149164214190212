import React from 'react';

const ExampleText: React.FC<{ example?: string, className?: string }> = ({ example, className }) => {
    if (!example) return null;

    // console.log("example", example);
  
    const parts = example.split('$').filter((_, index) => index % 2 === 0);
    const highlightedParts = example.split('$').filter((_, index) => index % 2 !== 0);

    return (
      <div className={`small-text ${className}`}>
        {parts.map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {index < highlightedParts.length && <span className="text-text-secondary">{highlightedParts[index]}</span>}
          </React.Fragment>
        ))}
      </div>
    );
}

export default ExampleText;