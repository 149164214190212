import React from 'react';
import { Card } from '../../../types';
import ExampleDiv from '../GameComponents/ExampleDiv';

interface FlashCardProps {
  flipped: boolean;
  toggleFlip: () => void;
  card: Card | null;
}

const FlashCard: React.FC<FlashCardProps> = ({ flipped, toggleFlip, card }) => {
  return (
    <div 
      className="relative transition-transform duration-600 cursor-pointer h-full md:h-80"
      onClick={toggleFlip}
    >
      <div 
        className="flashcard"
        style={{ backfaceVisibility: 'hidden', transition: 'transform 0.6s',
           transform: flipped ? 'rotateX(-180deg)' : 'rotateX(0)', transitionDuration: 'var(--transition-duration, 0.6s)' }}
      >
        <div className="flashcard-text-div">
          <div className="translation-solution-div">
            <div className="translation-solution-text-large">{card?.lemmaLangFrom}</div>
            <div className="small-text md:text-base">{card?.pos}</div>
          </div>

          <ExampleDiv example={card?.exampleLangFrom} className="text-center" />
        </div>
      </div>
      <div 
        className="flashcard" 
        style={{ backfaceVisibility: 'hidden', transition: 'transform 0.6s',
          transform: flipped ? 'rotateX(0)' : 'rotateX(180deg)', transitionDuration: 'var(--transition-duration, 0.6s)' }}
      >
        <div className="flashcard-text-div">
          <div className="translation-solution-div">
          <div className="translation-solution-text-large">{card?.lemmaLangTo}</div>
            <div className="small-text md:text-base">{card?.pos}</div>
          </div>
          <ExampleDiv example={card?.exampleLangTo} className="text-center" />
        </div>
      </div>
    </div>
  );
};

export default FlashCard;
