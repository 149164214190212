import React from 'react';
import Hero from './Hero';
import Demo from './DemoSection/Demo';
import Features from './Features';
import Testimonials from './Testimonials';
import CallToAction from './CallToAction';
import Faq from './FrequentlyAskedQuestions';
import Pricing from './Pricing';
import Navbar from '../WebsiteBaseComponents/Navbar';
import Footer from '../WebsiteBaseComponents/Footer';

const HomePage: React.FC = () => {
  return (
    <div className="website-layout">
      <Navbar />      
      <Hero />
      <Demo />
      <Features />
      {/* <Testimonials />
      <Pricing /> */}
      <CallToAction />
      <Faq />
      <Footer />
    </div>
  );
};

export default HomePage;
