import React, { useState, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderWithBubble from '../GameComponents/HeaderWithBubble';
import WriteInput from './WriteInput';
import WriteControls from './WriteControls';
import { Card, ExerciseState, GameMode, GameState, GameTag } from '../../../types';
import useWriteKeyboardControl from './useWriteKeyboardControls';
import { useAuth } from '../../../authentification/AuthContext';
import { MainLayout } from '../../baseComponents/Layout';
import evaluateExerciseState from '../../../utils/exerciseState';
import handleTerminateVocabularyGame from '../../../utils/terminateVocabularyGame';
import useInitializeCard from '../GameComponents/useInitializeCard';
import ModalInterruptGame from '../GameComponents/ModalInterruptGame';
import GameProgressBar from '../GameComponents/GameProgressBar';

const WritePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { gameMode, nextExerciseType: exerciseType, cards, chatId, numberCardsToReview } = location?.state?.gameState as GameState;

  const [idxCurrentCard, setIdxCurrentCard] = useState<number>(0);
  const [showAnswerButtons, setShowAnswerButtons] = useState(false);
  const [userResponse, setUserResponse] = useState('');
  const [exerciseState, setExerciseState] = useState<ExerciseState>(ExerciseState.Unanswered);
  const inputRef = useRef<HTMLInputElement>(null);
  const { user } = useAuth();
  const { cardsWithScores, setCardsWithScores, currentCard, setCurrentCard, initialTime } = useInitializeCard(gameMode, exerciseType, cards);

  const [showModalInterruptGame, setShowModalInterruptGame] = useState(false);

  const handleRevealAnswerClick = useCallback(async () => {
    if (userResponse !== '') {
      // Remove focus from the input field to remove the keyboard on mobile
      inputRef.current?.blur();
      setExerciseState(evaluateExerciseState(userResponse, currentCard.lemmaLangTo, gameMode));
      
      setShowAnswerButtons(true);
    } else {
      inputRef.current?.focus();
    }
  }, [userResponse, currentCard, gameMode]);

  
  const handleNextButtonClick = useCallback(async () => {
    // Update the score of the current card
    const score = exerciseState === ExerciseState.Wrong ? false : true;
    let udpatedCards = cardsWithScores.map((card, index) => 
      index === idxCurrentCard ? { ...card, score, userResponse } : card
    ); 

    // Add the card to the review list if the answer was wrong
    if (exerciseState === ExerciseState.Wrong) {
      const wrongCard = { ...currentCard, tag: 'review' as GameTag };
      udpatedCards = [...udpatedCards, wrongCard];
    }
  
    // console.log('udpatedCards', udpatedCards);
    // Move to the next card or terminate the game
    if (idxCurrentCard < udpatedCards.length - 1) {
      setCardsWithScores(udpatedCards);
      setShowAnswerButtons(false);
      setExerciseState(ExerciseState.Unanswered);
      setUserResponse('');
      setIdxCurrentCard(idxCurrentCard + 1);
      setCurrentCard(udpatedCards[idxCurrentCard + 1]);
    } else if (user && udpatedCards) {
      handleTerminateVocabularyGame(gameMode, exerciseType, user, navigate, udpatedCards, initialTime, true, chatId, numberCardsToReview);
    }
  }, [cardsWithScores, idxCurrentCard, user, initialTime, navigate, setCurrentCard, currentCard, exerciseState, userResponse, setCardsWithScores, gameMode, exerciseType, chatId, numberCardsToReview]);

  useWriteKeyboardControl(showAnswerButtons, handleRevealAnswerClick, handleNextButtonClick);

  return (user && currentCard) && (
    <MainLayout hasNavbar={false} hasFooterControls={true} isFullHeight={true}>
      <div className="flex flex-col h-screen gap-6 mg:gap-8">
        <GameProgressBar 
          cards={cardsWithScores}
          idxCurrentCard={idxCurrentCard}
          setShowModalInterruptGame={setShowModalInterruptGame}
        />
        <div className="game-page-wrapper game-div-with-max-width">
          <HeaderWithBubble gameMode={GameMode.Write} card={currentCard} />
          <WriteInput 
            userResponse={userResponse}
            setUserResponse={setUserResponse}
            showAnswerButtons={showAnswerButtons}
            exerciseState={exerciseState}
            gameMode={gameMode}
            solution={currentCard.lemmaLangTo ?? ''}
          />
        </div>
      </div>
      <WriteControls 
        showAnswerButtons={showAnswerButtons}
        exerciseState={exerciseState}
        card={currentCard || {} as Card}
        handleRevealAnswerClick={handleRevealAnswerClick}
        handleNextButtonClick={handleNextButtonClick}
        userResponse={userResponse}
        messageRole={gameMode}
      />
      <ModalInterruptGame
        isOpen={showModalInterruptGame}
        setIsOpen={setShowModalInterruptGame}
        onCancel={() => handleTerminateVocabularyGame(gameMode, exerciseType, user, navigate, cardsWithScores, initialTime, false, chatId, numberCardsToReview, true)}
      />
    </MainLayout>
  );
};

export default WritePage;