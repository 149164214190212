// src/components/BaseComponents/Layout.tsx

import React from 'react';
import Tag from '../../baseComponents/Tag';

interface SectionProps {
  children: React.ReactNode;
  className?: string;
}

export const Section: React.FC<SectionProps> = ({ children, className }) => {
  return (
    <section className={`py-16 text-center ${className}`}>
      {children}
    </section>
  );
};

export const SectionContainer: React.FC<{ children: React.ReactNode, className?: string}> = ({ children, className }) => {
  return (
    <div className={`max-w-7xl mx-auto layout-padding-x flex justify-center items-center flex-col gap-16 ${className}`}>
      {children}
    </div>
  );
};

interface HeadingProps {
  tag?: string;
  title: string;
  description: string;
  position?: 'center' | 'left' | 'right';
}

export const Heading: React.FC<HeadingProps> = ({ title, description, tag, position }) => {
  return (
    <div className={`flex flex-col max-w-3xl items-center text-center ${position === 'center' ? '' : 'md:items-start md:text-left'}`}>
      {tag && <Tag text={tag} variant='primary' className='mb-3'/>}
      <h2 className="text-3xl md:text-4xl font-semibold mb-5">{title}</h2>
      <p className="text-text-tertiary text-xl md ">{description}</p>
    </div>
  );
};
