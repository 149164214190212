import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ChevronRightWhite from '../../assets/imagesApp/chevron-right-white.svg';
import FooterControls from '../appComponents/FooterControls';
import { useLanguage } from '../../languages/LanguageContext';
import handleStartVocabularyGame from '../../utils/startVocabularyGame';
import { useAuth } from '../../authentification/AuthContext';
import LearnImage from '../../assets/imagesApp/listen-mode.svg';
import RefreshImage from '../../assets/imagesApp/home-multiple-choice.svg';
import Button from '../baseComponents/Button';
import EndGameLayout from './EndGameLayout';
import ChevronLeftSecondary from '../../assets/imagesApp/chevron-left-tertiary.svg';
import { ExerciseType, GameMode, GameState, PageUrl } from '../../types';
import handleNewChapter from '../book/bookUtils/startChapter';

interface ExerciseDivProps {
    nextExerciseType?: ExerciseType;
    numberCardsToReview?: number;
}

export const ExerciseDiv: React.FC<ExerciseDivProps> = ({ nextExerciseType, numberCardsToReview }) => {
    const { getTextLangFrom } = useLanguage();

    const endChapterExercises = [
        {   
            type: ExerciseType.BookChapterReview,
            title: getTextLangFrom('EndChapterPage-chapter-review-title'),
            description: getTextLangFrom('EndChapterPage-chapter-review-description'),
            numberOfWords: numberCardsToReview + " " + getTextLangFrom('EndChapterPage-words'),
            imgSrc: LearnImage,
            imgAlt: "Image"
        },
        {
            type: ExerciseType.BookGeneralReview,
            title: getTextLangFrom('EndChapterPage-general-review-title'),
            description: getTextLangFrom('EndChapterPage-general-review-description'),
            numberOfWords: 10 + " " + getTextLangFrom('EndChapterPage-words'),
            imgSrc: RefreshImage,
            imgAlt: "Image"
        }
    ];


    return (
        <div className="flex flex-col max-w-lg gap-8 @md:gap-12 mt-12 text-left">
            {endChapterExercises.map((exercise, index) => (
                <div key={index} className="flex gap-3 @md:gap-6 max-w-[440px] items-center">
                    <div className="flex flex-col gap-1.5 relative">
                        <div className='absolute top-0 left-0 w-full flex justify-center'>
                            <div className={`h-14 -mt-14 w-2 rounded-full ml-1
                                ${exercise.type === nextExerciseType || endChapterExercises.findIndex((ex) => ex.type === nextExerciseType) > index ? "bg-bg-success" : "bg-bg-tertiary"}`}
                            />
                        </div>
                        {index !== endChapterExercises.length - 1 && (
                            <div className='absolute bottom-0 left-0 w-full flex justify-center'>
                                <div className={`h-14 -mb-14 w-2 rounded-full ml-1
                                    ${endChapterExercises.findIndex((ex) => ex.type === nextExerciseType) > index ? "bg-bg-success" : "bg-bg-tertiary"}`}
                                />
                            </div>
                        )}
                        <div className={`flex justify-center mt-4 mb-4 h-14 w-14 @sm:h-20 @sm:w-20 rounded-full p-2 
                            ${exercise.type === nextExerciseType || endChapterExercises.findIndex((ex) => ex.type === nextExerciseType) > index ? "bg-bg-success" : "bg-bg-tertiary"}`}
                        >
                            <img src={exercise.imgSrc} alt={exercise.imgAlt} />
                        </div>
                    </div>
                    <div className={`flex flex-col gap-3 px-4 py-3 flex-1 ${exercise.type === nextExerciseType && "rounded-lg bg-bg-secondary"}`}
                    >
                        <h2 className={`text-lg font-medium ${exercise.type === nextExerciseType ? "text-text-primary" : "text-text-tertiary"}`}>
                            {exercise.title}
                        </h2>
                        <div className={`flex flex-col gap-2 text-sm ${exercise.type === nextExerciseType ? "text-text-secondary" : "text-text-tertiary"}`}>
                            <p className='website-p'>{exercise.description}</p>
                            <p className="font-medium text-sm">{exercise.numberOfWords}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};


const EndChapterPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!location?.state?.gameState) {
            navigate(PageUrl.Library);
            return;
        }
    }, [location, navigate]);

    const { getTextLangFrom, getTextLangTo } = useLanguage();
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { nextExerciseType, chatId, cards, numberCardsToReview } = location.state.gameState as GameState;


    const startVocabularyGame = useCallback(() => {
        if (user && nextExerciseType === 'book-chapter-review') {
            handleStartVocabularyGame(GameMode.Write, nextExerciseType, user, navigate, setIsLoading, chatId, cards, numberCardsToReview);
        } else if (user && nextExerciseType === ExerciseType.BookGeneralReview) {
            handleStartVocabularyGame(GameMode.MultipleChoice, nextExerciseType, user, navigate, setIsLoading, chatId);
        }
    }, [user, nextExerciseType, navigate, chatId, cards, numberCardsToReview]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' || event.key === 'ArrowRight') {
                startVocabularyGame();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [startVocabularyGame]);

    return user && (
        <EndGameLayout
            title={getTextLangFrom('EndChapterPage-title')}
            isLoading={isLoading}
        >
            <ExerciseDiv nextExerciseType={nextExerciseType} numberCardsToReview={numberCardsToReview} />
            <FooterControls>
                <div className="footer-controls-action-div">
                    <Button 
                        size="control"
                        variant="secondary"
                        leadingIcon={ChevronLeftSecondary}
                        onClick={() => {
                            setIsLoading(true);
                            handleNewChapter(navigate, getTextLangTo, user, setIsLoading, undefined, chatId);
                            setIsLoading(false);
                        }}
                        text={getTextLangFrom('word-new-chapter')}
                    />
                    <Button 
                        size="control"
                        trailingIcon={ChevronRightWhite}
                        onClick={startVocabularyGame}
                        text={getTextLangFrom('word-next-exercise')}
                    />
                </div>
            </FooterControls>
        </EndGameLayout>
    );
};

export default EndChapterPage;
