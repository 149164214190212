import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useAuth } from '../../authentification/AuthContext';
import { User, Language, Theme , CefrLevel} from '../../types';
import { useLanguage } from '../../languages/LanguageContext';
import { MainLayout } from '../baseComponents/Layout';
import InputField from '../baseComponents/Input';
import { Divider } from '../baseComponents/Divider';
import Button from '../baseComponents/Button';
import { DropDownField } from '../baseComponents/DropDown';
import Checkbox from '../baseComponents/Checkbox';
import LoadingAnimation from '../baseComponents/LoadingAnimation';
import { useLocation } from 'react-router-dom';

const SettingsPage: React.FC = () => {
  const { user, updateLocalAndDbUser } = useAuth();
  const { getTextLangFrom } = useLanguage();
  const [username, setUsername] = useState<string>(user ? user.username : '');
  const cefrLevels: CefrLevel[] = Object.values(CefrLevel);
  const languagesFrom: Language[] = useMemo(() => [Language.English], []);
  const languagesTo: Language[] =  Object.values(Language);
  const themes: Theme[] = Object.values(Theme);
  const [theme, setTheme] = useState<Theme>(user ? user.theme : Theme.Light);
  const [langFrom, setLangFrom] = useState<Language>(user ? user.langFrom : Language.English);
  const [langTo, setLangTo] = useState<Language>(user ? user.langTo : Language.German);
  const [cefrLevel, setCEFRLevel] = useState<CefrLevel>(user ? user.cefrLevel : CefrLevel.A1);
  const [areButtonsDisabled, setAreButtonsDisabled] = useState<boolean>(true);
  const [isTesting, setIsTesting] = useState<boolean | undefined>(user ? user.isTesting : false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();

  // Function to map display text to language ID
  const mapTextToId = useCallback((text: any) => {
    const textToIdMap: Record<any, any> = {};
    languagesFrom.forEach(lang => {
      textToIdMap[getTextLangFrom(`language-${lang}`)] = lang;
    });
    languagesTo.forEach(lang => {
      textToIdMap[getTextLangFrom(`language-${lang}`)] = lang;
    });
    themes.forEach(th => {
      textToIdMap[getTextLangFrom(`theme-${th}`)] = th;
    });
    cefrLevels.forEach(level => {
      textToIdMap[getTextLangFrom(`cefr-level-${level}`)] = level;
    });
    return textToIdMap[text];
  }, [languagesFrom, languagesTo, themes, cefrLevels, getTextLangFrom]);

  const handleSave = useCallback(async () => {
    if (!user) return;
  
    setIsLoading(true);
  
    const updatedUser: User = {
      ...user,
      username,
      cefrLevel,
      langFrom,
      langTo,
      theme,
      isTesting,
    };
    
    try {
      await updateLocalAndDbUser(updatedUser);
      
      if (langTo !== user.langTo) {
        // Reset the books in the navigation state in order to fetch new books
        location.state.navigationState.books = undefined; 
      }
    } catch (error) {
      console.error('Failed to update user:', error);
    } finally {
      setAreButtonsDisabled(true);
      setIsLoading(false);
    }
  }, [user, username, cefrLevel, langFrom, langTo, theme, isTesting, updateLocalAndDbUser, location]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !areButtonsDisabled) {
        event.preventDefault();
        handleSave();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSave, areButtonsDisabled]);

  const handleCancel = () => {
    // Reset form fields with original user data
    if (user) {
      setUsername(user.username);
      setCEFRLevel(user.cefrLevel);
      setLangFrom(user.langFrom);
      setLangTo(user.langTo);
      setTheme(user.theme);
      setIsTesting(user.isTesting);
      setAreButtonsDisabled(true); // Reset the change tracking state
    }
  };

  return (
    <MainLayout hasNavbar={true} title={getTextLangFrom('word-settings')} hideStreak={true}>
      <div className="flex flex-col gap-5">
        <div className="flex justify-between gap-4 flex-col md:flex-row">
          <div className="w-full">
            <h2 className="text-lg font-semibold">{getTextLangFrom('SettingsPage-personal-info')}</h2>
            <p className="small-text">{getTextLangFrom('SettingsPage-supporting-text')}</p>
          </div>
          {/* <div className="flex md:hidden gap-5">
            <Button
              variant="secondary"
              onClick={handleCancel}
              disabled={!areButtonsDisabled}
              text={getTextLangFrom('word-cancel')}
            />
            <Button
              onClick={handleSave}
              disabled={!areButtonsDisabled}
              text={getTextLangFrom('word-save')}
            />
          </div> */}
        </div>
        <Divider />
        <form className="flex flex-col gap-5">
          <InputField
            label={getTextLangFrom('word-name')}
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              setAreButtonsDisabled(false);
            }}
          />
          <Divider />
          <DropDownField
            value={getTextLangFrom(`language-${langTo}`)}
            onChange={(value) => {
              setLangTo(mapTextToId(value));
              setAreButtonsDisabled(false);
            }}
            options={
              languagesTo
                .filter(lang => lang !== langFrom) // Filter out the language from the 'from' dropdown
                .map(lang => getTextLangFrom(`language-${lang}`))} 
            label={getTextLangFrom('SettingsPage-language-to')}
          />
          <DropDownField
            value={getTextLangFrom(`cefr-level-${cefrLevel}`)}
            onChange={(value) => {
              setCEFRLevel(mapTextToId(value));
              setAreButtonsDisabled(false);
              }}
              options={cefrLevels.map(level => getTextLangFrom(`cefr-level-${level}`))} 
              label={getTextLangFrom('SettingsPage-language-level')}
            />
            <Divider />
            <DropDownField
              value={getTextLangFrom(`theme-${theme}`)}
              onChange={(value) => {
              setTheme(mapTextToId(value));
              setAreButtonsDisabled(false);
            }}
              options={themes.map(th => getTextLangFrom(`theme-${th}`))}
              label={getTextLangFrom('word-theme')}
            />
          
          {user?.isTesting === true && (
            <>
              <Divider />
              <DropDownField
                value={getTextLangFrom(`language-${langFrom}`)}
                onChange={(value) => {
                  setLangFrom(mapTextToId(value));
                  setAreButtonsDisabled(false);
                }}
                options={languagesFrom
                          .filter(lang => lang !== langTo) // Filter out the language from the 'to' dropdown
                          .map(lang => getTextLangFrom(`language-${lang}`))}
                label={getTextLangFrom('SettingsPage-language-from')}
                />
            </>
          )}
        
          {user?.isTesting !== undefined && (
            <>
              <Divider />
              <div className="flex gap-3 items-center">
                <Checkbox
                  size="lg"
                  checked={isTesting || false}
                  onChange={() => {
                    setAreButtonsDisabled(false);
                    setIsTesting(!isTesting);
                  }}
                />
                <label className="text-sm">{getTextLangFrom('SettingsPage-is-testing')}</label>
              </div>
            </>
          )}
        </form>
        <Divider />
        <div className="flex gap-3 justify-start md:justify-end">
          <Button
            variant="secondary"
            onClick={handleCancel}
            disabled={areButtonsDisabled}
            text={getTextLangFrom('word-cancel')}
          />
          <Button
            onClick={handleSave}
            disabled={areButtonsDisabled}
            text={getTextLangFrom('word-save')}
          />
        </div>
      </div>
      <LoadingAnimation isLoading={isLoading} />
    </MainLayout>
  );
};

export default SettingsPage;
