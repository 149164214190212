const formatDateForClient = (date: Date, getTextLangFrom: (key: string) => string) => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();

    const seconds = Math.floor(diff / 1000);
    if (seconds < 60) {
        return getTextLangFrom('WordsTable-less-than-minute');
    }
    
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
        return `${minutes} ${getTextLangFrom('WordsTable-minutes-ago')}`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
        return `${hours} ${getTextLangFrom('WordsTable-hours-ago')}`;
    }

    const days = Math.floor(hours / 24);    
    if (days < 7) {
        return `${days} ${getTextLangFrom('WordsTable-days-ago')}`;
    }

    const months = Math.floor(days / 30);
    if (months < 12) {
        return `${months} ${getTextLangFrom('WordsTable-months-ago')}`;
    } 

    const years = Math.floor(days / 365);
    return `${years} ${getTextLangFrom('WordsTable-years-ago')}`;
};

export default formatDateForClient;