import { Message, Card, GameMode } from '../types';
import { apiUrl, fetchData } from './api';


// TODO: generalize getResponse function
export async function getMessageResponse<T>(message: Message, gameMode: GameMode, card?: Card): Promise<T> {
  return await fetchData<T>(`${apiUrl}/message/response`, 'POST', { message, gameMode, card });
}

export async function initializeChat(userId: number): Promise<Message[]> {
  return await fetchData<Message[]>(`${apiUrl}/chat/init?userId=${userId}`, 'GET');
}

export async function getGoogleTranslation(text: string, sourceLang: string, targetLang: string): Promise<string | void> {
  return await fetchData<string | void>(`${apiUrl}/message/translate`, 'POST', { text, sourceLang, targetLang });
}

export async function saveTranslationPreferences(userId: number, translationId: number, chatId: number, isSaveUku: boolean): Promise<void> {
  return await fetchData<void>(`${apiUrl}/user/save-translation-preferences`, 'POST', { userId, translationId, chatId, isSaveUku });
}