import React, { useEffect } from 'react';
import { Section } from '../../WebsiteBaseComponents/Layout';
import BookDemoDiv from './BookDemoDiv';
import Button from '../../../baseComponents/Button';
import RefreshIcon from '../../../../assets/imagesWebsite/refresh-ccw-02.svg';
import { useTheme } from '../../../../styles/ThemeContext';
import { Language, Theme } from '../../../../types';
import { useAuth } from '../../../../authentification/AuthContext';


// Keyframes for moving and resizing circles using Tailwind's animation utilities
const moveAndResize = `@keyframes moveAndResize {
  0% {
    transform: translate(0, 0) scale(1);
  }
  25% {
    transform: translate(-5vw, 3vh) scale(1.2);
  }
  50% {
    transform: translate(5vw, -3vh) scale(0.9);
  }
  75% {
    transform: translate(-3vw, -5vh) scale(1.1);
  }
  100% {
    transform: translate(3vw, 5vh) scale(0.95);
  }
}`;

const Demo: React.FC = () => {
  const languages = [{name: '🇪🇸 Spanish', code: 'es'}, 
    // {name: '🇫🇷 French', code: 'fr'}, // TODO: add french with translation
     {name: '🇩🇪 German', code: 'de'}, {name: '🇮🇹 Italian', code: 'it'}];

  const [language, setLanguage] = React.useState(languages[0].name);
  const [isMobile, setIsMobile] = React.useState(false);
  const themes = [{name: 'Dark theme', code: Theme.Dark}, {name: 'Light theme', code: Theme.Light}, 
    {name: 'Dusk theme', code: Theme.Dusk}];
  const { changeTheme } = useTheme();
  const { user } = useAuth();
  const [theme, setTheme] = React.useState(window.matchMedia && 
    window.matchMedia('(prefers-color-scheme: dark)').matches ? Theme.Dark : Theme.Light);

  useEffect(() => {
    if (user?.theme) {
      setTheme(user.theme);
    } else {
      setTheme(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? Theme.Dark : Theme.Light);
    }
  }, [user]);

  const [isSmallScreen, setIsSmallScreen] = React.useState(window.innerWidth <= 768);

  // Evaluate the screen size on resize and setIsSmallScreen accordingly
  useEffect(() => {
    const handleResize = () => {
      // console.log('Resizing ...', window.innerHeight, window.innerWidth);
      const mobileWidthThreshold = 768;
      // console.log(window.innerWidth);
      if (window.innerWidth <= mobileWidthThreshold) {
        setIsSmallScreen(true);
        setIsMobile(true);
      } else {
        setIsSmallScreen(false);
      }
      // console.log('isMobile', isMobile);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  return (
    <>
      {/* Injecting the keyframes into the page */}
      <style>{moveAndResize}</style>
      <Section>
        <div className="flex justify-center items-center flex-col gap-8 -mt-8">
          <div className="text-md text-center flex items-center gap-2 flex-wrap justify-center px-2">
          <span className="">You are reading</span>
            <span className="font-medium">Alice in Wonderland</span>
            <span className="">in</span>
            <Button
              size="sm"
              text={language}
              variant="secondary"
              trailingIcon={RefreshIcon}
              onClick={() => {
                const currentIndex = languages.findIndex(lang => lang.name === language);
                const newIndex = (currentIndex + 1) % languages.length;
                setLanguage(languages[newIndex].name);
              }}
            />
            {!isSmallScreen && 
              <>
                <span className="">on</span>
                <Button
                  size="sm"
                  text={isMobile ? 'Mobile' : 'Desktop'}
                  variant="secondary"
                  trailingIcon={RefreshIcon}
                  onClick={() => setIsMobile(!isMobile)}
                />
              </>
            }
            <span className="">with</span>
            <Button
              size="sm"
              text={themes.find(t => t.code === theme)?.name}
              variant="secondary"
              trailingIcon={RefreshIcon}
              onClick={() => {
                const currentIndex = themes.findIndex(t => t.code === theme);
                const newIndex = (currentIndex + 1) % themes.length;
                changeTheme(themes[newIndex].code);
                setTheme(themes[newIndex].code);
              }}
            />
          </div>
          <div
            className={`relative flex relative justify-center items-center px-2
              ${isMobile ? ' h-[80vh] min-h-[650px] max-h-[720px] max-w-[350px] w-full' : 'h-[70vh] min-h-[700px] max-h-[850px] max-w-[1200px] w-[80vw] '}`}
          >
            {/* Background circles with animations using Tailwind */}
            <div
              className={`absolute w-[400px] h-[400px] rounded-full bg-bg-brand filter blur-[60px] animate-[moveAndResize_10s_infinite_alternate_ease-in-out] 
                ${theme.includes('dark') ? 'opacity-40' : 'opacity-60'}`}
              style={{ animationDelay: '0s' }}
            />
            <div
              className={`absolute w-[400px] h-[400px] rounded-full bg-bg-error filter blur-[60px] animate-[moveAndResize_10s_infinite_alternate_ease-in-out]
                ${theme.includes('dark') ? 'opacity-40' : 'opacity-60'}`}
              style={{ animationDelay: '2s' }}
            />
            <div
              className={`absolute w-[400px] h-[400px] rounded-full bg-brand-yellow filter blur-[60px] animate-[moveAndResize_10s_infinite_alternate_ease-in-out]
                ${theme.includes('dark') ? 'opacity-40' : 'opacity-60'}`}
              style={{ animationDelay: '4s' }}
            />
              <BookDemoDiv theme={theme} language={languages.find(lang => lang.name === language)?.code as Language}/>
          </div>
        </div>
      </Section>
    </>
  );
};

export default Demo;
