import React from 'react';
import CardTag from './GameCardTag';
import { useLanguage } from '../../../languages/LanguageContext';
import AvatarWriteImage from '../../../assets/imagesApp/robot-writing.svg';
import { Card, GameMode, User } from '../../../types';
import ExampleText from './ExampleDiv';

interface HeaderWithBubbleProps {
  gameMode: GameMode;
  card?: Card;
}

const HeaderWithBubble: React.FC<HeaderWithBubbleProps> = ({ gameMode, card }) => {
  const { getTextLangFrom } = useLanguage();

  const evaluateMdHeight = () => {
    if (gameMode !== GameMode.MultipleChoice) {
      return 'md:h-[200px]';
    }
    
    if (window.innerHeight > 900) {
      return 'md:h-[200px]';
    } else if (window.innerHeight > 800) {
      return 'md:h-[160px]';
    } else {
      return 'md:h-[120px]';
    }
  }

  return (
    <div className="game-page-wrapper game-div-with-max-width">
      <CardTag tag={card?.tag} />
      <div className="game-page-header">
        <h1 className="game-page-header-h3">{getTextLangFrom('WritePage-title')}</h1>
      </div>

      <div className="flex pt-2 gap-2 min-h-[110px]">
        <div className={`h-[60px] sm:h-[120px] ${evaluateMdHeight()} flex-shrink-0 mt-10 md:mt-0`}>
          <img src={AvatarWriteImage} alt="Avatar" className="w-full h-full" />
        </div>

        <div className="flex flex-col h-full">
        <div 
          className="flex flex-col bg-bg-tertiary border border-border-tertiary gap-0.5 md:gap-2
          rounded-lg rounded-bl-sm md:rounded-2xl md:rounded-bl-md
          px-3 py-2 md:px-6 md:py-4
          transition-all duration-300 ease-in-out
          min-h-6 md:min-h-12 max-w-[300px] md:max-w-[400px]"
        >
          <div className="translation-solution-div">
            <div className="translation-solution-text">{card?.lemmaLangFrom}</div>
            <div className="small-text mb-0.5 md:mb-0">{card?.pos}</div>
          </div>
          <ExampleText example={card?.exampleLangFrom} />
        </div>
      </div>
      </div>

    </div>
  );
};

export default HeaderWithBubble;
