import TickIcon from '../../assets/images/tick-white.svg';


const Stepper: React.FC<{ currentStep: number; totalSteps: number }> = ({ currentStep, totalSteps }) => {
    return (
      <div className="flex w-full justify-center items-center">
        <div className="flex items-center">
            {[...Array(totalSteps)].map((_, index) => {
            const isCompleted = index < currentStep -1;
            const isOngoing = index === currentStep - 1;
    
            return (
                <div key={index} className="flex items-center">
                    {index !== 0 && (
                        <div className={`h-1 w-12 flex-1 ${(isCompleted || isOngoing) ? 'bg-stepper-borderCompleted' : 'bg-stepper-borderUncompleted'}`}></div>
                    )}
                    <div
                        className={`z-10 w-6 h-6 flex items-center justify-center rounded-full border-2
                            ${(isCompleted || isOngoing) ? 'bg-stepper-bgCompleted border-stepper-borderCompleted' :
                                'bg-stepper-bgUncompleted border-stepper-borderUncompleted' }
                            ${isOngoing ? 'shadow-ring-brand-xs' : ''}`}
                    >
                        {isCompleted ? <img className='w-3' src={TickIcon} alt="Tick" /> :
                            <div className={`w-2 h-2 rounded-full ${isOngoing ? 'bg-stepper-bgCircleOngoing' : 'bg-stepper-bgCircleUncompleted'}`}></div>
                        }
                    </div>
                </div>
            );
            })}
        </div>
      </div>
    );
  };
  
  export default Stepper;