import React, { useState, useRef } from 'react';
import DeleteIcon from '../../assets/images/badge-close-x.svg';
import Tooltip from './Tooltip';

interface TagProps {
  text: string;
  size?: 'sm' | 'md' | 'lg';
  rounded?: 'lg' | 'full';
  icon?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  hasDot?: boolean;
  hasCircle?: boolean;
  className?: string;
  onDelete?: () => void;
  tooltip?: { text: string; position: 'top' | 'bottom' | 'left' | 'right' };
}

const Tag: React.FC<TagProps> = ({
  text,
  size = 'sm',
  rounded = 'full',
  icon,
  variant = 'secondary',
  hasDot,
  hasCircle,
  className,
  onDelete,
  tooltip,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const hoverTimeoutRef = useRef<number | null>(null);

  const handleMouseEnter = () => {
    hoverTimeoutRef.current = window.setTimeout(() => {
      setShowTooltip(true);
    }, 500); // Set delay to 500ms
  };

  const handleMouseLeave = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    setShowTooltip(false);
  };

  // Define size-based classes
  const sizeClasses = {
    sm: 'text-xs px-2 py-1 gap-1',
    md: 'text-sm px-3 py-1.5 gap-2',
    lg: 'text-base px-4 py-1.5 gap-2.5',
  };

  return (
    <div
      className={`relative flex items-center font-medium whitespace-nowrap border rounded-${rounded}
        ${variant === 'primary' ? 'text-tag-fgPrimary bg-tag-bgPrimary border-tag-borderPrimary'
          : variant === 'secondary' ? 'text-tag-fgSecondary bg-tag-bgSecondary border-tag-borderSecondary'
          : variant === 'tertiary' ? 'text-tag-fgTertiary bg-tag-bgTertiary border-tag-borderTertiary' : ''}
        ${sizeClasses[size]} ${className}`}
      style={{ width: 'fit-content' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {hasCircle && <div className="w-3 h-3 bg-gradient-to-br from-bg-brand to-red-500 rounded-full ml-[-4px]"></div>}
      {icon && <img src={icon} alt="tag" className="w-4 h-4 ml-[-4px]" />}
      {hasDot && <div className="w-1.5 h-1.5 bg-bg-brand rounded-full ml-[-4px]"></div>}
      {text}
      {onDelete && (
        <button
          className="flex items-center justify-center w-5 h-5 rounded-md hover:bg-buttonSecondary-bgHover mr-[-4px]"
          onClick={onDelete}
        >
          <img src={DeleteIcon} alt="delete" className="w-5 h-5" />
        </button>
      )}
      {tooltip && tooltip.text && (
        <Tooltip text={tooltip.text} position={tooltip.position} isVisible={showTooltip} />
      )}
    </div>
  );
};

export default Tag;
