import React, { useState } from 'react';
import { Section, SectionContainer } from '../WebsiteBaseComponents/Layout';
import { Heading } from '../WebsiteBaseComponents/Layout';

interface FaqItem {
  question: string;
  answer: string;
}

const faqData: FaqItem[] = [
  {
    question: 'What is Booklet?',
    answer: 'Booklet is a platform that helps you learn new languages by reading your favorite book.',
  },
  {
    question: 'How much does Booklet cost? Is it really free?',
    answer: 'Yes, Booklet is free to use, and will remain free unless the costs of running the service increase significantly.',
  },
  {
    question: 'Can I use Booklet on my phone? Is it in the Apple Store or Google Play Store?',
    answer: 'Yes, you can use it on your mobile, but there is no dedicated mobile app yet. You can access Booklet from this website. In addition, on certain phones, once you log in to the app, you will be asked if you would like to download and install it as a mobile app.',
  },
];

const Faq: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <Section>
      <SectionContainer>
        <Heading
          title="Frequently asked questions"
          description="Everything you need to know about Booklet"
          position='center'
        />
        <div className="max-w-3xl w-full text-left">
          {faqData.map((item, index) => (
            <div key={index} className={`border-b border-border-primary ${index === faqData.length - 1 ? 'border-b-0' : ''}`}>
              <button
                className={`w-full text-left py-6 text-lg font-semibold focus:outline-none ${
                  activeIndex !== index ? 'hover:bg-bg-tertiary' : ''
                }`}
                onClick={() => handleToggle(index)}
              >
                {item.question}
              </button>
              <div
                className={`text-text-tertiary overflow-hidden mb-8 ${
                  activeIndex === index ? 'block max-h-screen opacity-100' : 'hidden max-h-0 opacity-0'
                }`}
              >
                {activeIndex === index && item.answer}
              </div>
            </div>
          ))}
        </div>
      </SectionContainer>
    </Section>
  );
};

export default Faq;
