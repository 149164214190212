import React, { forwardRef } from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  hasIcon?: boolean;
  label?: string;
  hasCustomStyle?: boolean;
}

// Use forwardRef to pass the ref to the input element
const Input = forwardRef<HTMLInputElement, InputProps>(({ hasIcon, label, hasCustomStyle, className, ...props }, ref) => {
  return (
    <div className="form-div">
      {label && <label className="form-label">{label}</label>}
      <input
        autoCapitalize="none"        // Disable auto-capitalization
        inputMode="text"             // Specify text input mode
        autoComplete="off"           // Disable autocomplete suggestions
        autoCorrect="off"            // Disable autocorrect
        spellCheck="false"           // Disable spell check
        pattern=".*"                 // Prevent some auto-suggestions
        enterKeyHint="go"            // Customize Enter key behavior
        ref={ref} // Forward the ref here
        className={`${!hasCustomStyle && 'input-base border border-border-primary rounded-lg focus:shadow-ring-brand-xs px-3 disabled:cursor-not-allowed' }
                    ${hasIcon ? 'pl-10' : ''} ${className}`} // Allow additional classes
        {...props}
      />
    </div>
  );
});

export default Input;
