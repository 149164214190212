import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, ExerciseType, GameMode, PageUrl } from '../../../types';

const useInitializeCard = (gameMode: GameMode, nextExerciseType: ExerciseType, cards?: Card[]) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [currentCard, setCurrentCard] = useState<Card>({} as Card);
  const [cardsWithScores, setCardsWithScores] = useState<Card[]>([]);
  const [initialTime, setInitialTime] = useState<Date>(new Date());

  useEffect(() => {
    if (!(cards && cards.length > 0) || !gameMode || !nextExerciseType) {
      console.error('Invalid location state', location.state?.gameState, gameMode, nextExerciseType);
      navigate(PageUrl.Exercises);
    } else if (cardsWithScores.length === 0 && cards.length > 0) {
      setCardsWithScores(cards);
      setCurrentCard(cards[0]);
      setInitialTime(new Date());
    }
  }, [location, cards, cardsWithScores, gameMode, nextExerciseType, navigate]);

  return { cardsWithScores, setCardsWithScores, currentCard, setCurrentCard, initialTime };
};

export default useInitializeCard;