import React from 'react';
import { Section, SectionContainer } from '../WebsiteBaseComponents/Layout';
import Button from '../../baseComponents/Button';
import Tag from '../../baseComponents/Tag';
import { useNavigate } from 'react-router-dom';
import { PageUrl } from '../../../types';

const Hero: React.FC = () => {
  const navigate = useNavigate();
  
  return (
    <Section>
      <SectionContainer>
        <div className="flex flex-col items-center text-center">
          <div className="flex items-center mb-4 gap-2">
            <Tag text="New" variant='primary' size='sm' />
            <p className="text-sm font-medium text-text-brand">Booklet has just opened the waiting list 🎉</p>
          </div>
          <h1 className="text-4xl md:text-6xl font-semibold mb-6">
            Learn languages by reading books
          </h1>
          <p className="text-lg text-text-tertiary mb-8">
            Booklet helps you learn a new language by reading the book you love.
          </p>
          <Button text="Get started" onClick={() => navigate(PageUrl.SignUp)} />
        </div>
      </SectionContainer>
    </Section>
  );
};

export default Hero;
