import { ExerciseState } from "../../types";

interface FooterControlsProps {
  isRelativePosition?: boolean;
  exerciseState?: string;
  children: React.ReactNode;
}

const FooterControls: React.FC<FooterControlsProps> = ({ isRelativePosition = false, exerciseState, children,  }) => {
  const backgroundColorClass = exerciseState === ExerciseState.Correct ? 'bg-bg-success border-bg-success'
    : exerciseState === ExerciseState.Wrong ? 'bg-bg-error border-bg-error'
    : exerciseState === ExerciseState.AlmostCorrect ? 'bg-bg-warning border-bg-warning'
    : 'border-border-secondary';

  return (
    <div
      className={`${isRelativePosition ? 'relative' : 'fixed'} ${backgroundColorClass} bottom-0 left-0 z-10 flex justify-center
        border-t w-full @md:h-24 py-4 @md:py-0 layout-padding-x`}
    >
      <div className="max-w-[800px] flex flex-1 flex-col @md:flex-row">
        {children}
      </div>
    </div>
  );
}

export default FooterControls;
