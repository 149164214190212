import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../baseComponents/Button';
import { MainLayout } from '../baseComponents/Layout';
import { NavbarBackAndGameProgress } from '../baseComponents/ProgressBar';
import ChevronRightWhite from '../../assets/imagesApp/chevron-right-white.svg';
import ChevronRightSecondary from '../../assets/imagesApp/chevron-left-tertiary.svg';
import { useLanguage } from '../../languages/LanguageContext';
import FooterControls from '../appComponents/FooterControls';
import { OnboardingData, PageUrl, User, UserStatus } from '../../types';
import { useAuth } from '../../authentification/AuthContext';
import LoadingAnimation from '../baseComponents/LoadingAnimation';

interface OnboardingLayoutProps {
    step: number;
    children: React.ReactNode;
    isActionButtonDisabled?: boolean;
    isFullHeight?: boolean;
}

const stepDirectories = [PageUrl.OnboardingWelcome, PageUrl.OnboardingLanguage, PageUrl.OnboardingLevel, PageUrl.Library];

const OnboardingLayout: React.FC<OnboardingLayoutProps> = ({ step, children, isActionButtonDisabled, isFullHeight }) => {
    const navigate = useNavigate();
    const { getTextLangFrom } = useLanguage();
    const location = useLocation();
    const onboardingState = location.state?.onboardingState as OnboardingData;
    const {user, updateLocalAndDbUser} = useAuth();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);


    const handleButtonClick = async (directory: string) => {
        // console.log("onboardingState", onboardingState);
        setIsLoading(true);
        if (user && directory === PageUrl.Library) {
            const updatedUser = { 
                ...user, 
                langTo: onboardingState.language,
                cefrLevel: onboardingState.cefrLevel,
            } as User;
            await updateLocalAndDbUser(updatedUser);
        }
        navigate(directory, { state: { onboardingState } });
        setIsLoading(false);
    }
  
    useEffect(() => {
      if (!onboardingState) {
        location.state = { onboardingState: {} as OnboardingData };
      }
    }, [location, onboardingState]);

    //console.log("onboardingState", onboardingState);

    return (
        <MainLayout hasNavbar={false} isFullHeight={isFullHeight} hasFooterControls={true}>
            <NavbarBackAndGameProgress sessionProgress={step * 25} onBackClick={() => navigate(PageUrl.Login)} />
            <div className="flex flex-col items-center gap-10 max-w-[560px] ml-auto mr-auto flex-1 w-full">
                {children}
            </div>
            <FooterControls>
                <div className="footer-controls-action-div">
                    { step !== 1 && (
                    <Button
                        variant="secondary"
                        size="control"
                        leadingIcon={ChevronRightSecondary}
                        onClick={() => handleButtonClick(stepDirectories[step - 2])}
                        text={getTextLangFrom('word-back')}
                    />
                    )}
                    <Button
                        size="control"
                        trailingIcon={ChevronRightWhite}
                        onClick={() => handleButtonClick(stepDirectories[step])}
                        text={getTextLangFrom('word-next')}
                        disabled={isActionButtonDisabled}
                    />
                </div>
            </FooterControls>
            <LoadingAnimation isLoading={isLoading} />
        </MainLayout>
    );
};

export default OnboardingLayout;