import React, { useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GameState, GameTag } from '../../../types';
import CardTag from '../GameComponents/GameCardTag';
import FlashCard from './FlashCard';
import FlashCardControls from './FlashCardControls';
import { useAuth } from '../../../authentification/AuthContext';
import useFlashCardKeyboardControls from './useFlashCardKeyboardControls';
import { useLanguage } from '../../../languages/LanguageContext';
import { MainLayout } from '../../baseComponents/Layout';
import handleTerminateVocabularyGame from '../../../utils/terminateVocabularyGame';
import useInitializeCard from '../GameComponents/useInitializeCard';
import ModalInterruptGame from '../GameComponents/ModalInterruptGame';
import GameProgressBar from '../GameComponents/GameProgressBar';

const FlashCardPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { gameMode, chatId, nextExerciseType: exerciseType, cards, numberCardsToReview } = location?.state?.gameState as GameState;
  const [idxCurrentCard, setIdxCurrentCard] = useState<number>(0);
  const { user } = useAuth();
  const [flipped, setFlipped] = useState(false);
  const [hasFlipped, setHasFlipped] = useState(false);
  const { getTextLangFrom } = useLanguage();
  const { cardsWithScores, setCardsWithScores, currentCard, setCurrentCard, initialTime } = useInitializeCard(gameMode, exerciseType, cards);
  const [showModalInterruptGame, setShowModalInterruptGame] = useState(false);

  const toggleCardFlip = () => {
    setFlipped(prev => !prev);
    setHasFlipped(true);
  };
  
  const handleNextCard = useCallback(async (score: boolean) => {
    // Update the score of the current card
    let udpatedCards = cardsWithScores.map((card, index) => 
      index === idxCurrentCard ? { ...card, score } : card
    ); 

    // Add the card to the review list if the answer was wrong
    if (score === false) {
      const wrongCard = { ...currentCard, tag: 'review' as GameTag };
      udpatedCards = [...udpatedCards, wrongCard];
    }

    // Move to the next card or terminate the game
    if (idxCurrentCard < udpatedCards.length - 1) {
      setCardsWithScores(udpatedCards);
      setFlipped(false);
      setHasFlipped(false);
      setIdxCurrentCard(idxCurrentCard + 1);
      setCurrentCard(cardsWithScores[idxCurrentCard + 1]);
    } else if (user && udpatedCards) {
      handleTerminateVocabularyGame(gameMode, exerciseType, user, navigate, udpatedCards, initialTime, true, chatId, numberCardsToReview);
    }
  } , [cardsWithScores, currentCard, idxCurrentCard, gameMode, user, initialTime, chatId, navigate, setCurrentCard, setCardsWithScores, exerciseType, numberCardsToReview]);

  const handleCorrectScore = useCallback(() => handleNextCard(true), [handleNextCard]);
  const handleIncorrectScore = useCallback(() => handleNextCard(false), [handleNextCard]);

  // Use the custom hook for keyboard controls
  useFlashCardKeyboardControls(hasFlipped, toggleCardFlip, handleCorrectScore, handleIncorrectScore);

  return user && (
    <MainLayout hasNavbar={false} hasFooterControls={true} isFullHeight={true}>
      <GameProgressBar 
        cards={cardsWithScores}
        idxCurrentCard={idxCurrentCard}
        setShowModalInterruptGame={setShowModalInterruptGame}
      />
      <div className="game-page-wrapper game-div-with-max-width flex-1">
        <CardTag tag={currentCard?.tag} />
        <div className="game-page-header">
          <h3 className="game-page-header-h3">
            {getTextLangFrom('FlashcardPage-title')}
          </h3>
        </div>
        <FlashCard flipped={flipped} toggleFlip={toggleCardFlip} card={currentCard} />
      </div>
      <FlashCardControls 
        hasFlipped={hasFlipped}
        toggleCardFlip={toggleCardFlip}
        handleCorrectScore={handleCorrectScore}
        handleIncorrectScore={handleIncorrectScore}
      />
      <ModalInterruptGame
        isOpen={showModalInterruptGame}
        setIsOpen={setShowModalInterruptGame}
        onCancel={() => handleTerminateVocabularyGame(gameMode, exerciseType, user, navigate, cardsWithScores, initialTime, false, chatId, numberCardsToReview, true)}
      />
    </MainLayout>
  );
};

export default FlashCardPage;
