import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../languages/LanguageContext';
import CloseIcon from '../../assets/images/x-close.svg';
import { Book, ChapterForNavigation } from '../../types';
import LoadingAnimation from '../baseComponents/LoadingAnimation';
import { useAuth } from '../../authentification/AuthContext';
import ChapterList from './ChapterList';
import Button from '../baseComponents/Button';
import handleNewChapter from '../book/bookUtils/startChapter';


const BookNavigation = ({ book, chaptersForNavigation, onClose }: { book: Book | undefined; chaptersForNavigation: ChapterForNavigation[]; onClose: () => void }) => {
  const [openChapterNumber, setOpenChapterNumber] = useState<number | null>(null);
  const [selectedChapterNumber, setSelectedChapterNumber] = useState<number | undefined>(undefined);
  const [expandedChapterNumber, setExpandedChapterNumber] = useState<number | null>(null);
  const navigate = useNavigate();
  const { getTextLangFrom, getTextLangTo } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();

  const handleChapterClick = (index: number) => {
    setOpenChapterNumber(openChapterNumber === index ? null : index);
  };

  const handleContentClick = (chapterNumber: number) => {
    setSelectedChapterNumber((prev) => (prev === chapterNumber ? undefined : chapterNumber));
  };

  const toggleContentExpansion = async (event: React.MouseEvent<HTMLButtonElement>, chapterNumber: number) => {
    event.stopPropagation(); // Prevents the event from bubbling up to the PartDiv
    if (expandedChapterNumber === chapterNumber) {
      setExpandedChapterNumber(null); // Collapse the chapterNumber if already expanded
    } else {
      setExpandedChapterNumber(chapterNumber);
    }
  };

  const isButtonDisabled = selectedChapterNumber === undefined;

  return user && (
    <div className="overlay-page">
      <div className="bg-bg-primary p-5 rounded-lg max-w-md max-h-[560px] w-full flex flex-col gap-5">
        <div className="flex justify-between">
          <div className="flex flex-col gap-1">
            <h2 className="text-xl font-semibold">{getTextLangFrom('BookNavigation-title')}</h2>
            <p className="small-text">{getTextLangFrom('BookNavigation-subtitle')}</p>
          </div>
          <Button variant="tertiary" onClick={onClose} trailingIcon={CloseIcon} iconOnly={true} />
        </div>
        <ChapterList
          chaptersForNavigation={chaptersForNavigation}
          openChapterNumber={openChapterNumber}
          setOpenChapterNumber={handleChapterClick}
          selectedChapterNumber={selectedChapterNumber}
          setSelectedChapterNumber={handleContentClick}
          expandedChapterNumber={expandedChapterNumber}
          setExpandedChapterNumber={toggleContentExpansion}
        />
        <Button
          className="w-full"
          disabled={isButtonDisabled}
          onClick={() => book && handleNewChapter(navigate, getTextLangTo, user, setIsLoading, book.id, undefined, selectedChapterNumber)}
          text={getTextLangFrom('word-start')}
        />
      </div>
      <LoadingAnimation isLoading={isLoading} />
    </div>
  );
};

export default BookNavigation;
