import { Card, ExerciseRecord, Message, BookExercise, ExerciseState, Language } from "../../../types";
import { addParticleToLemma} from "../../../utils/useAddParticlesToLemmas";

function bookExerciseToCard(bookExercise: BookExercise, langTo: Language): Card {
  // console.log("bookExercise:", bookExercise);
  return {
    translationId: bookExercise.translationId,
    lemmaLangFrom: addParticleToLemma(langTo, bookExercise.lemmaLangFrom, bookExercise.pos, bookExercise.genderLangFrom),
    exampleLangFrom: bookExercise.exampleLangFrom,
    lemmaLangTo: addParticleToLemma(langTo, bookExercise.lemmaLangTo, bookExercise.pos, bookExercise.genderLangTo),
    exampleLangTo: bookExercise.exampleLangTo,
    score: bookExercise.score ?? false, // Default to `false` if `score` is not provided
    pos: bookExercise.pos, // If no `pos`, you may choose a default
  };
}


function evaluateCardScore(exerciseState: ExerciseState): boolean | undefined {
  if (exerciseState === ExerciseState.Correct || exerciseState === ExerciseState.AlmostCorrect) {
    return true;
  } else if (exerciseState === ExerciseState.Wrong) {
    return false;
  } else {
    return undefined;
  }
}

function extractRecordsFromChapterMessages(chapterMessages: Message[], langTo: Language): {exerciseRecords: ExerciseRecord[], cardsForReviewExercise: Card[], accuracy: number} {
  let totalExercises = 0;
  let wrongAnswers = 0;

  let exerciseRecords = [] as ExerciseRecord[];
  let cardsForReviewExercise = [] as Card[];

  // console.log('chapterMessages', chapterMessages)

  chapterMessages.forEach((message: Message) => {
    if (message.bookExercises) {
      message.bookExercises.forEach((exercise: string | BookExercise) => {
        if (typeof exercise !== 'string') {           
          // console.log("isexercise:", exercise.isExercise);
          const score = evaluateCardScore(exercise.exerciseState);
          if (exercise.isExercise === true && score !== undefined) {
            // console.log("Saving exercise:", exercise);
            exerciseRecords.push({
              translationId: exercise.translationId,
              userResponse: exercise.userResponse,
              score: score,
            });
            totalExercises++;
            if (score === false) {
              wrongAnswers++;
              cardsForReviewExercise.push(bookExerciseToCard(exercise, langTo));
            }
          }
        }
      });
    }
  });

  const accuracy = (totalExercises - wrongAnswers) / totalExercises;

  return { exerciseRecords, cardsForReviewExercise, accuracy };
}

export default extractRecordsFromChapterMessages;