import React from 'react';
import { Card } from '../../../types';
import { NavbarBackAndGameProgress } from '../../baseComponents/ProgressBar';

interface GameProgressBarProps {
  cards: Card[];
  idxCurrentCard: number;
  setShowModalInterruptGame: any;
}

const GameProgressBar: React.FC<GameProgressBarProps> = ({ cards, idxCurrentCard, setShowModalInterruptGame }) => {
  

  return (
    <NavbarBackAndGameProgress 
      sessionProgress={(cards.length > 0) ? idxCurrentCard / cards.length * 100 : 0}
      onBackClick={() => setShowModalInterruptGame(true)}
    />
  );
};

export default GameProgressBar;
