import { useNavigate } from 'react-router-dom';
import { initializeVocabularyGame } from '../api/Games';
import { Card, GameMode, GameState, ExerciseType, User, gameModeToUrl, PageUrl } from '../types';
import { addParticlesToLemmas } from './useAddParticlesToLemmas';

const handleStartVocabularyGame = async (
  gameMode: GameMode,
  exerciseType: ExerciseType,
  user: User,
  navigate: ReturnType<typeof useNavigate>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  chatId?: number,
  cards?: Card[],
  numberCardsToReview?: number,
  ) => {
    if (!user) {
        navigate(PageUrl.Library);
        return null;
    }

    setIsLoading(true);

    // If no cards are provided, retrieve them from the server
    if (!cards) {
        const newCards= await initializeVocabularyGame(user.id, gameMode );
        cards = addParticlesToLemmas(newCards, user.langFrom, user.langTo);
    }

    const gameState: GameState = {
        gameMode: gameMode,
        chatId: chatId,
        nextExerciseType: exerciseType,
        cards: cards,
        numberCardsToReview: numberCardsToReview,
    };
    
    setIsLoading(false);
    navigate(gameModeToUrl(gameMode), { state: { gameState } });
  };

export default handleStartVocabularyGame;
