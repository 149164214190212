import React from 'react';
import { Table } from '@tanstack/react-table';
import Button from './Button';
import { useLanguage } from '../../languages/LanguageContext';

type PaginationControlsProps = {
  table: Table<any>;
};

const PaginationControls: React.FC<PaginationControlsProps> = ({ table }) => {
  const { getTextLangFrom } = useLanguage();

  return (
    <div className="flex justify-between items-center py-2 px-4">
      <div className="flex">
        {/* Fixed-width container for Previous button */}
        <div style={{ width: '40px' }}>
          {table.getCanPreviousPage() ? (
            <Button text="Previous" variant="tertiary" onClick={() => table.previousPage()} />
          ) : (
            <span className="invisible">
              {getTextLangFrom('word-previous')}
            </span>
          )}
        </div>
      </div>
      <div className="flex items-center h-[42px]">
        <span className="small-text font-medium">
          {getTextLangFrom('word-page')} {table.getState().pagination.pageIndex + 1} {getTextLangFrom('Pagination-word-of')} {table.getPageCount()}
        </span>
      </div>
      <div className="flex justify-end">
        {/* Fixed-width container for Next button */}
        <div className="flex justify-end" style={{ width: '40px' }}>
          {table.getCanNextPage() ? (
            <Button text="Next" variant="tertiary" onClick={() => table.nextPage()} />
          ) : (
            <span className="invisible">
              {getTextLangFrom('word-next')}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaginationControls;
