import React, { useState, useEffect } from 'react';
import {
  ColumnDef,
  SortingState,
} from '@tanstack/react-table';
import SearchBar from '../baseComponents/SearchBar';
import { PageUrl, Word } from '../../types';
import { CellScore, CellTranslation, CellLastStudied } from './TableCellStyles';
import { useLanguage } from '../../languages/LanguageContext';
import Table from '../baseComponents/Table';
import { useNavigate } from 'react-router-dom';

function WordsTable({ wordTableData }: { wordTableData: Word[] }) {
  const [filter, setFilter] = useState<string>('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const { getTextLangFrom } = useLanguage();
  const navigate = useNavigate();

  // State to manage hidden columns
  const [hiddenColumnsIds, setHiddenColumnsIds] = useState<string[]>([]);

  const filteredData = React.useMemo(() => {
    return wordTableData.filter(word =>
      (word.lemmaLangFrom?.toLowerCase()?.includes(filter.toLowerCase()) || '') ||
      (word.lemmaLangTo?.toLowerCase()?.includes(filter.toLowerCase()) || '')
    );
  }, [wordTableData, filter]);

  const columns = React.useMemo<ColumnDef<Word>[]>(
    () => [
      {
        accessorKey: 'score',
        size: 100,
        cell: ({ row }) => (
          <CellScore score={row.original.score} key={row.id} />
        ),
        header: () => getTextLangFrom('word-score'),
      },
      {
        accessorKey: 'lemmaLangTo',
        header: () => getTextLangFrom('word-word'),
        cell: ({ row }) => (
          <CellTranslation
            lemmaLangFrom={row.original.lemmaLangFrom}
            lemmaLangTo={row.original.lemmaLangTo}
          />
        ),
      },
      {
        accessorKey: 'lastSeen',
        size: 160,
        cell: ({ row }) => (
          <CellLastStudied lastSeen={row.original.lastSeen} />
        ),
        header: getTextLangFrom('word-last-studied'),
      },
    ],
    [getTextLangFrom]
  );

  useEffect(() => {
    setSorting([{ id: 'lastSeen', desc: true }]);
  }, [wordTableData]);

  // useEffect to hide/show `lastSeen` column based on screen width
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia('(max-width: 640px)').matches) {
        setHiddenColumnsIds(['lastSeen']);
      } else {
        setHiddenColumnsIds([]);
      }
    };

    // Attach the event listener and check initially
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check on component mount

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="table-and-search-wrapper">
      <SearchBar filter={filter} setFilter={setFilter} />
      <Table
        data={filteredData}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        idInitialSortKey={'position'}
        isInitialSortAsc={true}
        hiddenColumnsIds={hiddenColumnsIds}
        emptyStateProps={
          {
            title: getTextLangFrom('WordTable-empty-state-title'),
            description: getTextLangFrom('WordTable-empty-state-description'),
            ctaText: getTextLangFrom('WordTable-empty-state-ctaText'),
            onCtaClick: () => navigate(PageUrl.Library),
          }
        }
      />
    </div>
  );
}

export default WordsTable;
