import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi'; // Importing the arrow icon
import { BookExercise, ExerciseState } from '../../types';

interface SolutionTextProps {
  part: BookExercise;
  handleShowTooltip: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, part: BookExercise | string) => void;
}

const SolutionTextWrapper: React.FC<SolutionTextProps> = ({ part, handleShowTooltip }) => {
  const getTextClasses = (exerciseState?: ExerciseState) => {
    if (exerciseState === ExerciseState.Wrong) return 'text-text-error line-through';
    if (exerciseState === ExerciseState.AlmostCorrect) return 'text-text-warning line-through';
    return 'text-text-success';
  };

  return part.exerciseState === ExerciseState.Correct ? (
    <span 
      className={`inline-block font-medium cursor-pointer ${getTextClasses(part.exerciseState)}`} 
      onClick={(event) => handleShowTooltip(event, part)}
    >
      {part.solution}
    </span>
  ) : (
    <>
      <span className={`inline-block font-medium ${getTextClasses(part.exerciseState)}`}>
        {part.userResponse}
      </span>
      <span className="inline-block font-medium">
        <span className="inline-block">
          &nbsp;
          <HiArrowNarrowRight className='inline-block w-5 h-5 text-text-brand'/>
          &nbsp;
        </span>
      </span>
      <span 
        className="inline-block font-medium cursor-pointer text-text-success" 
        onClick={(event) => handleShowTooltip(event, part)}
      >
        {part.solution}
      </span>
    </>
  );
};

export default SolutionTextWrapper;
