import React, { useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Fireworks from '../baseComponents/FireworksAnimation';
import CelebratingBotImage from '../../assets/imagesApp/robot-celebrating-success.svg';
import AccuracyIcon from '../../assets/imagesApp/accuracy-icon.svg';
import SpeedIcon from '../../assets/imagesApp/speed-icon.svg';
import ChevronRightWhite from '../../assets/imagesApp/chevron-right-white.svg';
import ChevronLeftSecondary from '../../assets/imagesApp/chevron-left-tertiary.svg';
import FooterControls from '../appComponents/FooterControls';
import { useLanguage } from '../../languages/LanguageContext';
import handleStartVocabularyGame from '../../utils/startVocabularyGame';
import { useAuth } from '../../authentification/AuthContext';
import { User, GameState, PageUrl, ExerciseType, GameMode } from '../../types';
import Button from '../baseComponents/Button';
import EndGameLayout from './EndGameLayout';
import handleNewChapter from '../book/bookUtils/startChapter';

export const StatsDiv: React.FC< { accuracy?: number, gameDuration?: number } > = ({ accuracy, gameDuration }) => {
    const { getTextLangFrom } = useLanguage();
    
    return (
        <>
            <Fireworks />
            <img src={CelebratingBotImage} alt="Chatbot" className="w-full  max-w-[280px] @sm:max-w-[300px] aspect-square" />
            <div className="flex gap-4 mt-6 w-full justify-center">
                <div className="endgame-stats-wrapper bg-brand-c2">
                    <div className="endgame-stats-title">{getTextLangFrom('EndGameStatsPage-accuracy')}</div>
                    <div className="endgame-stats-text-wrapper">
                        <img src={AccuracyIcon} alt="Accuracy" className="endgame-stats-icon" />
                        <p className="endgame-stats-number">{Math.round( (accuracy || 0) * 100 )}%</p>
                    </div>
                </div>
                <div className="endgame-stats-wrapper bg-brand-yellow">
                    <div className="endgame-stats-title">{getTextLangFrom('EndGameStatsPage-speed')}</div>
                    <div className="endgame-stats-text-wrapper">
                        <img src={SpeedIcon} alt="Speed" className="endgame-stats-icon" />
                        {/* Convert the session duration in seconds to a string in the format "mm:ss" */}
                        <p className="endgame-stats-number">{new Date((gameDuration || 0) * 1000).toISOString().substring(14, 19)}</p>
                    </div>
                </div>
            </div>
        </>
    );
};
  
const EndGameStatsPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { getTextLangFrom, getTextLangTo } = useLanguage();
    const { user, updateLocalAndDbUser } = useAuth();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isStreakExtended, setIsStreakExtended] = React.useState<boolean>(false);

    // Check if the location state is valid and contains the game data
    useEffect(() => {
        if (!location?.state?.gameState) {
            navigate(PageUrl.Library);
        }
    }, [location, navigate]);
    
    const { gameMode, chatId, accuracy, gameDuration, nextExerciseType, cards } = location.state.gameState as GameState;

    // Check if the streak should be extended
    useEffect(() => {
        if ( gameMode === GameMode.Book && isStreakExtended === false ) {
            const isStreakToBeExtended = user?.last7dStreakDays[user.last7dStreakDays.length - 1] === false
            if (  isStreakToBeExtended ) {
                const updatedUser: User = {
                    ...user,
                    last7dStreakDays: [
                        ...user.last7dStreakDays.slice(0, -1), // Keep all but the last element
                        true, // Set the last element to true
                    ],
                    numberStreakDays: user.numberStreakDays + 1,
                };
                updateLocalAndDbUser(updatedUser)
                setIsStreakExtended(true);
                // console.log("Streak extended");
            }
        }
    }, [user, updateLocalAndDbUser, isStreakExtended, gameMode]);

    const handleTerminateChapterClick = useCallback(() => {
        if ( isStreakExtended === true ) {
            navigate(PageUrl.StreakExtension, { state: { gameState: location.state.gameState } });
        } else if ( isStreakExtended === false ) {
            navigate(PageUrl.BookReviewExercises, { state: { gameState: location.state.gameState } });
        } else {
            console.error("isStreakExtended is undefined");
        }
    }, [navigate, location.state.gameState, isStreakExtended]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (user && (event.key === 'Enter' || event.key === 'ArrowRight')) {
                if (nextExerciseType === ExerciseType.BookChapterReview || nextExerciseType === ExerciseType.BookGeneralReview) {
                    navigate(PageUrl.BookReviewExercises, { state: { gameState: location.state.gameState } });
                } else if (nextExerciseType === ExerciseType.NextChapter) {
                    handleNewChapter(navigate, getTextLangTo, user, setIsLoading, undefined, chatId);
                } else {
                    handleStartVocabularyGame(gameMode, nextExerciseType, user, navigate, setIsLoading, chatId, cards);
                }
            } else if (event.key === 'Escape' || event.key === 'ArrowLeft') {
                navigate(PageUrl.Library);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [user, nextExerciseType, navigate, location.state.gameState, gameMode, chatId, cards, getTextLangFrom, getTextLangTo]);

    return (
        <EndGameLayout
            title={getTextLangFrom('EndGameStatsPage-title')}
            isLoading={isLoading}
        >
            <StatsDiv accuracy={accuracy} gameDuration={gameDuration} />
            <FooterControls>
                <div className="footer-controls-action-div">
                    { (user && (nextExerciseType === ExerciseType.BookChapterReview || nextExerciseType === ExerciseType.BookGeneralReview)) ? (
                        <Button
                            size="control"
                            trailingIcon={ChevronRightWhite} 
                            onClick={() => handleTerminateChapterClick()}
                            text={getTextLangFrom('word-next')}
                        />
                    ) : (user && nextExerciseType === ExerciseType.NextChapter) ? (
                        <Button
                            size="control"
                            trailingIcon={ChevronRightWhite}
                            onClick={() => {
                                handleNewChapter(navigate, getTextLangTo, user, setIsLoading, undefined, chatId);
                            }}
                            text={getTextLangFrom('word-next-chapter')}
                        />
                    ) : (user) &&
                    <>
                        <Button
                            size="control"
                            variant="secondary"
                            leadingIcon={ChevronLeftSecondary}
                            onClick={() => navigate(PageUrl.Exercises)}
                            text={getTextLangFrom('word-exit')}
                        />
                        <Button
                            size="control"
                            trailingIcon={ChevronRightWhite}
                            onClick={() => handleStartVocabularyGame(gameMode, nextExerciseType, user, navigate, setIsLoading, chatId, cards)}
                            text={getTextLangFrom('word-play-again')}
                        />
                    </>
                    }
                </div>
            </FooterControls>
        </EndGameLayout>
    );
};

export default EndGameStatsPage;