import React, { useState, useEffect } from 'react';
import LoginImage from '../../assets/imagesApp/login_robot.svg';
import SignupImage from '../../assets/imagesApp/signup-robot.svg';
import { useLanguage } from '../../languages/LanguageContext';
import GoogleLoginButton from './GoogleLoginButton';
import { useNavigate } from 'react-router-dom';
import Button from '../baseComponents/Button';
import { PageUrl } from '../../types';
import Fireworks from '../baseComponents/FireworksAnimation';

const LoginPage: React.FC = () => {
  const { getTextLangFrom } = useLanguage();
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [isLoginPage, setIsLoginPage] = useState<boolean>(false);

  useEffect(() => {
    const path = window.location.pathname;
    setIsLoginPage(path === PageUrl.Login);
  }, []);

  const handleSwitchLoginSignup = () => {
    setIsLoginPage(!isLoginPage);
    setError('');
    navigate(isLoginPage ? PageUrl.SignUp : PageUrl.Login);
  };

  return (
    <div className="flex items-center justify-center h-screen bg-bg-primary lg:bg-bg-secondary">
      <div className="flex flex-col items-center justify-center bg-bg-primary rounded-lg p-8 max-w-[440px] w-full gap-6 shadow-none lg:shadow-sm">
        <div className="flex justify-center w-full aspect-square">
          <img src={isLoginPage ? LoginImage : SignupImage} alt="login" className="w-full max-w-[350px] aspect-[1/1]" />
        </div>
        <div className="flex flex-col items-center text-center gap-2 text-text-tertiary">
          <h1 className="text-text-primary text-xl font-semibold">{isLoginPage ? getTextLangFrom('LoginPage-title') : getTextLangFrom('SignupPage-title')}</h1>
          <p className="text-text-tertiary">{isLoginPage ? getTextLangFrom('LoginPage-subtitle') : getTextLangFrom('SignupPage-subtitle')}</p>
        </div>
        {error && <div className="text-text-error">{error}</div>}
        <GoogleLoginButton setError={setError} isLoginPage={isLoginPage} />
        <div className="small-text flex gap-2 justify-center items-center flex-wrap">
          {isLoginPage ? getTextLangFrom('LoginPage-no-account-text') : getTextLangFrom('SignupPage-have-account-text')}
          <Button
            isLink={true}
            variant="primary"
            onClick={handleSwitchLoginSignup}
            text={isLoginPage ? getTextLangFrom('word-sign-up') : getTextLangFrom('word-login')}
          />
        </div>
      </div>
      {!isLoginPage && <Fireworks />}
    </div>
  );
};

export default LoginPage;
