import React, { useState } from 'react';
import { useLanguage } from '../../languages/LanguageContext';
import BookExerciseDiv from './BookExerciseDiv';
import { NavbarBackAndGameProgress } from '../baseComponents/ProgressBar';


const BookPage: React.FC = () => {
  const [wrapperHeight, setWrapperHeight] = useState(window.innerHeight); // Initial height is the full window height
  const [sessionProgress, setSessionProgress] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { getTextLangFrom } = useLanguage();

  return (
    <div 
      className="relative flex-1 w-screen overflow-hidden"
      style={{ height: `${wrapperHeight}px`}}
    >
      <div className='flex flex-col h-full overflow-hidden'>
        <div className="px-4 pt-6 md:px-6 md:pt-8">
          <NavbarBackAndGameProgress
            sessionProgress={sessionProgress}
            onBackClick={() => setIsModalOpen(true)}
          />
        </div>
        <BookExerciseDiv setSessionProgress={setSessionProgress} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      </div>
    </div>
  );
};

export default BookPage;
