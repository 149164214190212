import React, { useState, useEffect } from 'react';
import { Book, ChapterForNavigation, NavigationState, PageUrl, User } from '../../types';
import { useLocation, useNavigate } from 'react-router-dom';
import { getBooksAndStreak, deleteBook, getChaptersForNavigation } from '../../api/Books';
import { useLanguage } from '../../languages/LanguageContext';
import { useAuth } from '../../authentification/AuthContext';
import HorizontalMenu from '../baseComponents/HorizontalMenu';
import { MainLayout } from '../baseComponents/Layout';
import SearchBar from '../baseComponents/SearchBar';
import BookCard from './BookCard';
import BookNavigation from './BookNavigation';
import Button from '../baseComponents/Button';
import Modal from '../baseComponents/Modal';
import LoadingAnimation from '../baseComponents/LoadingAnimation';
import { Loader } from '../baseComponents/Loader';

export enum LibraryName {
  UserLibrary = "user-library",
  BookletLibrary = "booklet-library"
}
const LibraryPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [books, setBooks] = useState<Book[] | null>(null);
  const [isOpeningBook, setIsOpeningBook] = useState<boolean>(false);
  const [isLoadingBooks, setIsLoadingBooks] = useState<boolean>(false);
  const { getTextLangFrom } = useLanguage();
  const { user, updateLocalUser } = useAuth();
  const [selectedLibraryName, setSelectedLibraryName] = useState<LibraryName>(LibraryName.UserLibrary);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [selectedBookId, setSelectedBookId] = useState<number>(-1);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [chaptersForNavigation, setChaptersForNavigation] = useState<ChapterForNavigation[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [hasFetchedBooks, setHasFetchedBooks] = useState(false);


  const handleDeleteBook = async () => {
    if (books && user) {
      const updatedBooks = await deleteBook(selectedBookId, user.id, user.langTo);
      setBooks(updatedBooks);
      setIsModalOpen(false);
      location.state.navigationState.books = updatedBooks;
    }
  }

  useEffect(() => {
    if (selectedOption && books) {
      const Book = books.find(book => book.id === selectedBookId);
      if (selectedOption === getTextLangFrom('word-edit')) {
        navigate(PageUrl.BookEdit, { state: { Book } });
      } else if (selectedOption === getTextLangFrom('word-delete')) {
        setIsModalOpen(true);
      } else if (selectedOption === getTextLangFrom('word-navigate')) {
        getChaptersForNavigation(selectedBookId).then((chaptersForNavigation) => {
          setChaptersForNavigation(chaptersForNavigation);
        });
        setShowOverlay(true);
      }
      setSelectedOption('');
    }
  }, [selectedOption, selectedBookId, books, navigate, getTextLangFrom]);


  useEffect(() => {
    if (user && !hasFetchedBooks) {
      const updatedUser: User = { ...user };
      const navigationState = location?.state?.navigationState as NavigationState;
      const hasDataInLocationState = navigationState && (navigationState.books !== undefined && navigationState.last7dStreakDays !== undefined && navigationState.numberStreakDays !== undefined);

      if (hasDataInLocationState) {
        setBooks(navigationState.books);
        updatedUser.last7dStreakDays = navigationState.last7dStreakDays;
        updatedUser.numberStreakDays = navigationState.numberStreakDays;
        updateLocalUser(updatedUser);
        setHasFetchedBooks(true);
      } else if (user) {
        const fetchBooks = async () => {
          setIsLoadingBooks(true);
          try {
            const { numberStreakDays, last7dStreakDays, books: newBooks } = await getBooksAndStreak(user.id, user.langTo);
            updatedUser.last7dStreakDays = last7dStreakDays;
            updatedUser.numberStreakDays = numberStreakDays;
            updateLocalUser(updatedUser);
            setBooks(newBooks);
            setHasFetchedBooks(true);

            location.state = {
              ...location.state,
              navigationState: {
                ...location.state?.navigationState,
                books: newBooks,
                last7dStreakDays,
                numberStreakDays,
              } as NavigationState,
            };
          } catch (error) {
            console.error("Error fetching books and streak:", error);
          } finally {
            setIsLoadingBooks(false);
          }
        };
  
        fetchBooks();
      }
    }
  }, [user, updateLocalUser, hasFetchedBooks, location]);

  const filteredBooks = books?.filter(book =>
    book.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    book.author.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const tabs = [
    { text: getTextLangFrom('word-my-books'), handleClick: () => setSelectedLibraryName(LibraryName.UserLibrary) },
    { text: getTextLangFrom('LibraryPage-booklet-library'), handleClick: () => setSelectedLibraryName(LibraryName.BookletLibrary) }
  ];

  const isBookInLibrary = (library: LibraryName, book: Book): boolean => {
    if (library === LibraryName.UserLibrary) {
      return (book.uploaderId === user?.id || book.lastAccessed !== null);
    } else {
      return !book.uploaderId;
    }
  }

  const isLibraryEmpty = (library: LibraryName, books?: Book[]): boolean => {
    return !books?.some(book => isBookInLibrary(library, book));
  };

  return (
    <MainLayout 
      hasNavbar={true}
      title={getTextLangFrom('word-library')}
      isFullHeight={true}
      customPadding='layout-padding-x pt-4 md:pt-8'
    >
      <HorizontalMenu tabs={tabs} />
        { isLoadingBooks ? (
          <Loader />
        ) : (
          <>
            <div className="flex flex-col gap-5">
              <div className="flex gap-3">
                <SearchBar
                  filter={searchQuery}
                  setFilter={setSearchQuery}
                  placeholder={getTextLangFrom('SearchBar-placeholder')}
                  className="flex-1"
                />
                <Button 
                  className=""
                  variant="primary"
                  onClick={() => navigate(PageUrl.BookUpload, { state: { navigationState: location?.state?.navigationState as NavigationState } })}
                  text={getTextLangFrom('word-upload')}
                />
              </div>
              {selectedLibraryName === LibraryName.UserLibrary && isLibraryEmpty(LibraryName.UserLibrary, filteredBooks) && 
                <p className='small-text'>{getTextLangFrom('LibraryPage-show-booklet-books')}</p>
              }
            </div>
            <div className="flex flex-col gap-2 md:gap-3 overflow-y-auto py-4 -mt-4 h-full">
              {user && filteredBooks
                ?.filter(book => 
                  (selectedLibraryName === LibraryName.BookletLibrary && isBookInLibrary(LibraryName.BookletLibrary, book)) || 
                  (selectedLibraryName === LibraryName.UserLibrary && 
                    ( 
                     ( !isLibraryEmpty(LibraryName.UserLibrary, filteredBooks) && isBookInLibrary(LibraryName.UserLibrary, book))  ||                   
                     ( isLibraryEmpty(LibraryName.UserLibrary, filteredBooks) && isBookInLibrary(LibraryName.BookletLibrary, book)) 
                    )
                  )
                )
                .map((book, index) => (
                  <div key={index}>
                    <BookCard
                      book={book}
                      user={user}
                      setIsLoading={setIsOpeningBook}
                      setSelectedOption={setSelectedOption}
                      setSelectedBookId={setSelectedBookId}
                    />
                  </div>
              ))}
              {showOverlay && (
                <BookNavigation
                  book={books?.find(book => book.id === selectedBookId)}
                  chaptersForNavigation={chaptersForNavigation}
                  onClose={() => setShowOverlay(false)}
                />
              )}
            </div>
          </>
        )}
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={getTextLangFrom('modal-delete-book-title')}
        message={getTextLangFrom('modal-delete-book-message')}
        confirmText={getTextLangFrom('word-delete')}
        cancelText={getTextLangFrom('word-back')}
        onConfirm={() => handleDeleteBook()}
        onCancel={() => setIsModalOpen(false)}
      />
      <LoadingAnimation isLoading={isOpeningBook} />
    </MainLayout>
  );
};

export default LibraryPage;
