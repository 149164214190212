import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Message, BookExercise, TranslationTooltip, ExerciseState, MessageRole, GameMode, PageUrl, Language, Theme } from '../../types';
import { useLanguage } from '../../languages/LanguageContext';
import { useLocation, useNavigate } from 'react-router-dom';
import BookFooterControls from './BookFooterControls';
import MessageContent from './MessageContent';
import TranslationTooltipDiv from './TranslationTooltip/TranslationTooltip';
import onBookMouseUp from './TranslationTooltip/onBookMouseUp';
import evaluateExerciseState from '../../utils/exerciseState';
import { useAuth } from '../../authentification/AuthContext';
import terminateChapter from './bookUtils/terminateChapter';
import Modal from '../baseComponents/Modal';

interface BookExerciseDivProps {
  setSessionProgress: React.Dispatch<React.SetStateAction<number>>;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  demoMessages?: Message[];
  setDemoGameDuration?: React.Dispatch<React.SetStateAction<number>>;
  setDemoMessages?: React.Dispatch<React.SetStateAction<Message[]>>;
  demoLangTo?: Language;
}

const BookExerciseDiv: React.FC<BookExerciseDivProps> = ({ setSessionProgress, isModalOpen, setIsModalOpen, demoMessages, setDemoGameDuration, setDemoMessages, demoLangTo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [tooltip, setTooltip] = useState({} as TranslationTooltip);
  const [lastMessageExParts, setLastMessageExParts] = useState<(string | BookExercise)[]>([]);
  const { getTextLangFrom } = useLanguage();
  const [focusedExerciseIndex, setFocusedExerciseIndex] = useState<number>(-1);
  const [messageRole, setMessageRole] = useState<MessageRole>(MessageRole.BookTranslate);
  const [buttonText, setButtonText] = useState(getTextLangFrom('word-continue'));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [exerciseState, setExerciseState] = useState<ExerciseState>(ExerciseState.Unanswered);
  const [learnWordPreferences, setLearnWordPreferences] = useState<{ translationId: number, isToLearn: boolean }[]>([]);
  const { user } = useAuth();
  const [initialTime, setInitialTime] = useState<Date>(new Date());
  const [currentMessages, setCurrentMessages] = useState<Message[]>([]);
  const [chatId, setChatId] = useState<number>(-1);
  const [chapterMessages, setChapterMessages] = useState<Message[]>([]);
  const [numberIntroMessages, setNumberIntroMessages] = useState<number>(0);
  const [langFrom, setLangFrom] = useState<Language>(Language.English);
  const [langTo, setLangTo] = useState<Language>(Language.Spanish);
  const [isFirstExerciseOfChapter, setIsFirstExerciseOfChapter] = useState<boolean>(true);

  useEffect(() => {
    if (location.pathname === PageUrl.Website && demoMessages && setDemoGameDuration && setDemoMessages && demoLangTo) {
      setCurrentMessages(demoMessages.slice(0, 3));
      setChapterMessages(demoMessages.slice(3));
      setNumberIntroMessages(3);
      setInitialTime(new Date());
      setLangTo(demoLangTo);
    } else {
      if (!user || !location.state || !location.state.gameState || !location.state.gameState.chatId) {
        navigate(PageUrl.Website);
      } else {
        setChapterMessages(location.state.gameState.chapterMessages);
        setCurrentMessages(location.state.gameState.initialMessages);
        setNumberIntroMessages(location.state.gameState.initialMessages.length - 1)
        setChatId(location.state.gameState.chatId);
        setInitialTime(new Date());
        setLangFrom(user?.langFrom || Language.English);
        setLangTo(user?.langTo || Language.Spanish);
      }
    }
  }, [location.pathname, demoMessages, setDemoGameDuration, setDemoMessages, demoLangTo, user, navigate, location.state]);

  const focusNextExercise = useCallback((bookExercises: (string | BookExercise)[]) => {
    // Remove focus from current input
    setFocusedExerciseIndex(-2);
    setExerciseState(ExerciseState.Unanswered);
  
    // Find next input
    for (let i = 0; i < bookExercises.length; i++) {
      if (typeof bookExercises[i] !== 'string' && (bookExercises[i] as BookExercise).isExercise && !(bookExercises[i] as BookExercise).userResponse) {
        setFocusedExerciseIndex(i);
        if (messageRole === MessageRole.BookTranslate) {
          const inputRef = inputRefs.current[i];
          inputRef?.focus();
        }
        return i;
      }
    }
    return -2;
  }, [messageRole]);

  const setMessagesAndScrollDown = useCallback((newMessages: Message[]) => {
    setCurrentMessages(newMessages);
    // Use setTimeout to delay scrolling
    setTimeout(() => {
      messagesContainerRef.current?.scrollTo({
        top: messagesContainerRef.current?.scrollHeight,
        behavior: 'smooth', // Smooth scrolling (optional)
      });
    }, 100); // Delay of 200ms
  }, [setCurrentMessages]);

  
  useEffect(() => {
    if (currentMessages.length !== 0) {
      // If there are exercises, prepare the first one
      setLastMessageExParts(currentMessages[currentMessages.length - 1].bookExercises || []);
      const lastMessageText = currentMessages[currentMessages.length - 1];
      setMessageRole(lastMessageText.messageRole);
    
      // Don't focus the first exercise of each chapter
      if (!isFirstExerciseOfChapter) {
        // console.log('Focus next exercise');
        focusNextExercise(lastMessageText?.bookExercises || []);
      }

      setMessagesAndScrollDown(currentMessages);
    }
  }, [currentMessages, setMessagesAndScrollDown, isFirstExerciseOfChapter, focusNextExercise]);

  const handleTerminateChapter = useCallback((isChapterInterrupted: boolean) => {
    if (location.pathname === PageUrl.Website && demoMessages && setDemoGameDuration && setDemoMessages) {
      setDemoMessages(chapterMessages);
      setDemoGameDuration((new Date().getTime() - initialTime.getTime()) / 1000);
    } else if (user) {
      terminateChapter(navigate, user.id, user.langTo, chatId, chapterMessages, initialTime, isChapterInterrupted);
    }
  }, [location.pathname, demoMessages, setDemoGameDuration, setDemoMessages, user, navigate, chatId, chapterMessages, initialTime]);

  const handleNextClick = useCallback(async (userResponse: string) => {
    setIsFirstExerciseOfChapter(false);
    let inputRef = null as HTMLInputElement | null;

    if (focusedExerciseIndex === -2) {
      // End of exercise. Get the next messages
      setExerciseState(ExerciseState.Loading);
      setFocusedExerciseIndex(-1);

      if (currentMessages.length - numberIntroMessages === chapterMessages.length) {
        handleTerminateChapter(true);;
      } else {
        setExerciseState(ExerciseState.Unanswered);
        setSessionProgress((currentMessages.length - numberIntroMessages) / chapterMessages.length * 100);
        setMessagesAndScrollDown([...currentMessages, chapterMessages[currentMessages.length - numberIntroMessages]]);
      }
    } else {
      if (focusedExerciseIndex >= 0) {
        setExerciseState(ExerciseState.Loading);
        // console.log('Focused index', focusedExerciseIndex);
        inputRef = inputRefs.current[focusedExerciseIndex];
        userResponse = inputRef?.value || '';
        const exState = evaluateExerciseState(userResponse, (lastMessageExParts[focusedExerciseIndex] as BookExercise).solution, GameMode.Write);
        setExerciseState(exState);
        (lastMessageExParts[focusedExerciseIndex] as BookExercise).exerciseState = exState;
        // console.log('Exercise state', (lastMessageExParts[focusedExerciseIndex] as BookExercise).exerciseState);
        setButtonText(getTextLangFrom('word-continue'));
        setMessagesAndScrollDown(currentMessages);
      } else {
        const index = focusNextExercise(lastMessageExParts);
        if (index !== undefined) {
          inputRef = inputRefs.current[index];
        }
        return;
      }
    }

    const newBookExercises = [...lastMessageExParts];
    const part = newBookExercises[focusedExerciseIndex];
    if (typeof part !== 'string') {
      newBookExercises[focusedExerciseIndex] = { ...part, userResponse: userResponse };
    }

    currentMessages[currentMessages.length - 1].bookExercises = newBookExercises;
    setLastMessageExParts(newBookExercises);

    if (userResponse !== '') {
      setFocusedExerciseIndex(-1);
    } else {
      focusNextExercise(newBookExercises);
    }

  }, [currentMessages, chapterMessages, lastMessageExParts, focusNextExercise, focusedExerciseIndex, numberIntroMessages,
     setMessagesAndScrollDown, setSessionProgress, getTextLangFrom, handleTerminateChapter]);
  
  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (exerciseState !==  ExerciseState.Loading) {
        if (event.key === 'Enter') {
          event.preventDefault();
          if (focusedExerciseIndex < 0){
            await handleNextClick('');
          } else {
            await handleNextClick('');
          }
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [exerciseState, handleNextClick, focusedExerciseIndex]);

  const handleTranslateInputFocus = (index: number) => {
    setExerciseState(ExerciseState.Unanswered);
    setButtonText(getTextLangFrom('word-check'));
    setFocusedExerciseIndex(index);  
  };

  const handleTranslateInputBlur = () => {
    setButtonText(getTextLangFrom('word-continue'));
  };

  const handleOnMouseUp = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const target = event.target as HTMLElement;
    // console.log('Clicked on', target);
    const selection = window.getSelection();
    const isTextSelected = selection && selection.rangeCount > 0 && selection.toString().length > 0;

    if ( chatId && location.pathname !== PageUrl.Website && // terporarily disable translation on website main page. TODO: Fix tooltips on website
        (isTextSelected || !(target instanceof HTMLSpanElement && target.classList.contains('clickable-span')))) {
      onBookMouseUp(event, setTooltip, langFrom, langTo, currentMessages, getTextLangFrom, chatId, location.pathname as PageUrl);
    } 
  }

  return (
    <>
      <div className="chat-wrapper"  onMouseUp={handleOnMouseUp} >
        <div className="chat-inner-wrapper">
          <div className="chat-scroll-wrapper">
            <div className="chat-scroll-inner-div" ref={messagesContainerRef}>
              {currentMessages.map((message, index) => (
                <MessageContent
                  key={index}
                  index={index}
                  activeMessage={message}
                  messages={currentMessages}
                  langFrom={langFrom}
                  langTo={langTo}
                  tooltip={tooltip}
                  setTooltip={setTooltip}
                  messageRole={messageRole}
                  handleTranslateInputFocus={handleTranslateInputFocus}
                  handleTranslateInputBlur={handleTranslateInputBlur}
                  inputRefs={inputRefs}
                  chatId={chatId}
                  theme={user?.theme || Theme.Light}
                  userId={user?.id}
                />
              ))}
              <div><div className="w-full h-1"></div></div> {/* Spacer */}
              <TranslationTooltipDiv tooltip={tooltip} setTooltip={setTooltip} learnWordPreferences={learnWordPreferences} setLearnWordPreferences={setLearnWordPreferences} pageUrl={location.pathname as PageUrl} />
            </div>
          </div>
        </div>
      </div>
      <BookFooterControls
        bookExercises={lastMessageExParts}
        exerciseState={exerciseState}
        focusedExerciseIndex={focusedExerciseIndex}
        messageRole={messageRole}
        buttonText={buttonText}
        handleClick={handleNextClick}
        getTextLangFrom={getTextLangFrom}
      />
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={getTextLangFrom('modal-interrupt-header')}
        message={getTextLangFrom('modal-interrupt-reading-description')}
        confirmText={getTextLangFrom('word-resume')}
        cancelText={getTextLangFrom('word-leave')}
        onConfirm={() => setIsModalOpen(false)} 
        onCancel={() => handleTerminateChapter(false)}
      />
    </>
  );
};


export default BookExerciseDiv;