import React from 'react';
import CloseIcon from '../../assets/images/x-close.svg';
import RocketIcon from '../../assets/images/rocket.svg';
import Button from './Button';
import ReactTooltip from 'react-tooltip';


interface ProgressBarProps {
  sessionProgress: number;
  tooltipText?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ sessionProgress, tooltipText }) => {
  return (
    <div className="flex flex-1 h-5 bg-bg-tertiary rounded-full">
      <div
        data-tip={tooltipText}
        className="h-full bg-gradient-to-b from-bg-brand via-brand-c3 to-bg-brand rounded-[30px_20px_20px_30px] shadow-sm transition-width duration-500"
        style={{ width: `${sessionProgress}%` }}
      />
      <ReactTooltip />
    </div>
  );
};

interface NavbarBackProps {
  sessionProgress?: number;
  onBackClick: () => void;
}

export const NavbarBackAndGameProgress: React.FC<NavbarBackProps> = ({ sessionProgress, onBackClick }) => {
  return (
    <div className='progress-bar-wrapper'>
      <Button leadingIcon={CloseIcon} variant="tertiary" onClick={onBackClick} iconOnly={true} />
      <ProgressBar sessionProgress={sessionProgress || 0} />
      <img className="ml-3 w-8 h-8 @sm:w-10 @sm:h-10" src={RocketIcon} alt="rocket" />
    </div>
  );
};


export const NavbarBack: React.FC<NavbarBackProps> = ({ onBackClick }) => {
  return (
    <div className='progress-bar-wrapper'>
      <Button leadingIcon={CloseIcon} variant="tertiary" onClick={onBackClick} iconOnly={true} />
    </div>
  );
};
