import React from 'react';
import { MessageRole } from '../../types';

interface MessageDivProps {
  children: React.ReactNode;
  messageRole: string;
}

const MessageDiv: React.FC<MessageDivProps> = ({
  children,
  messageRole,
}) => {
  let roleClasses = 'flex mb-1 rounded-lg duration-600 max-w-[600px] mx-auto py-2 @md:py-3';

  if (messageRole === MessageRole.User) {
    roleClasses += ' bg-bg-secondary shadow-md self-end text-lg';
  }

  if (messageRole === MessageRole.Title) {
    roleClasses += ' text-xl font-medium  max-w-[450px] text-center mx-auto mt-6';
  } else if (messageRole === MessageRole.Intro) {
      roleClasses += ' text-base uppercase max-w-[450px] text-center mx-auto text-lg';
  } else {
    roleClasses += ' w-full text-lg';
  }

  return (
    <div className={roleClasses} >
      {children}
    </div>
  );
};

export default MessageDiv;
