import { useNavigate } from 'react-router-dom';
import { BookState, Message, MessageRole, PageUrl, User } from '../../../types';
import { startNewChapter } from '../../../api/Books';
import createMessagesWithBookExercises from './createMessageWithExercises';

const handleNewChapter = async (
  navigate: ReturnType<typeof useNavigate>,
  getTextLangTo: (key: string) => string,
  user: User,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  bookId?: number,
  chatId?: number,
  chapterNumber?: number
  ) => {
    if (!user) {
        navigate(PageUrl.Library);
        return null;
    }

    setIsLoading(true);

    const bookResponse = await startNewChapter(user, bookId, chatId, chapterNumber);
    
    if (bookResponse.isBookCompleted) {
      console.log('Book completed');
      setIsLoading(false);
      navigate(PageUrl.Library);
      return;
    }

    const chapterMessages = createMessagesWithBookExercises(bookResponse.text, bookResponse.bookExercises, user.cefrLevel);
    const titleMessage = { messageRole : MessageRole.Title, bookExercises: [bookResponse.title || getTextLangTo('word-chapter') + ' ' + (bookResponse.originalChapterNumber + 1)] } as Message;
    const introMessage = { messageRole: MessageRole.Intro, bookExercises: [`${getTextLangTo('word-part')} ${bookResponse.chapterNumberWithinOriginalChapter}`] } as Message;

    // console.log('chapterMessages', chapterMessages);

    const gameState: BookState = {
      initialMessages: [titleMessage, introMessage, chapterMessages[0]],
      chapterMessages: chapterMessages,
      chatId: bookResponse.chatId,
      chapterNumber: bookResponse.chapterNumber,
      bookExercises: bookResponse.bookExercises,
    };

    //console.log('gameState', gameState);
    setIsLoading(false);
    navigate(PageUrl.Book, { state: { gameState } });
  };

export default handleNewChapter;
