import { BookExercise } from "../../types";

const TextDiv = ({ children, isInDatabase, className }: { children: React.ReactNode, isInDatabase: boolean, className?: string }) => (
  <p className={`${isInDatabase ? 'whitespace-nowrap' : 'whitespace-normal'} ${className}`}>
    {children}
  </p>
);

const WordDetails = ({ children, size }: { children: React.ReactNode, size: 'small' | 'large' }) => (
  <div className={`flex flex-wrap italic mt-[2.5px] ${size === 'large' ? 'text-base' : 'text-sm'}`}>
    {children}
  </div>
);

const LangFromDetailsDiv = ({ bookExercise, size }: { bookExercise?: BookExercise, size: 'small' | 'large' }) => {
  const isInDatabase = bookExercise?.translationId !== undefined;
  
  return (
    <div className="flex gap-3 text-text-tertiary">
      <TextDiv isInDatabase={isInDatabase} className="text-text-brand font-medium">{bookExercise?.lemmaLangTo}</TextDiv>
      <WordDetails size={size}>
        {bookExercise?.pos}
        {bookExercise?.genderLangTo && <p className='website-p'>, {bookExercise?.genderLangTo}</p>}
        {bookExercise?.numberLangTo === "plural" && <p className='website-p'>, {bookExercise?.numberLangTo}</p>}
      </WordDetails>
      {bookExercise?.pluralLangTo && (
        <WordDetails size={size}>
          (pl: <TextDiv isInDatabase={isInDatabase} className="text-text-brand font-medium ml-1">{bookExercise?.pluralLangTo}</TextDiv>)
        </WordDetails>
      )}
    </div>
  );
};

const LangToDetailsDiv = ({ bookExercise, googleTranslation, size }: { bookExercise?: BookExercise, googleTranslation?: string, size: 'small' | 'large' }) => {
  const isInDatabase = bookExercise?.translationId !== undefined;
  let primaryTranslation = isInDatabase ? bookExercise.lemmaLangTo : googleTranslation;
  let otherTranslations: string[] = [];

  if (isInDatabase) {
    primaryTranslation = bookExercise.lemmaLangFrom;
    otherTranslations = bookExercise.otherLemmasLangFrom || [];
  }
  // console.log('otherTranslations:', otherTranslations);

  return (
    <div className="flex gap-3 text-text-tertiary">
      <TextDiv isInDatabase={isInDatabase} className="font-medium text-text-primary">{primaryTranslation}</TextDiv>
      {otherTranslations && otherTranslations.length > 0 && (
        <WordDetails size={size}>
          (also:{" "}
          {otherTranslations.map((translation, index) => (
            <TextDiv className="ml-[3px]" isInDatabase={isInDatabase} key={index}>
              {translation}{index < otherTranslations.length - 1 && ","}
            </TextDiv>
          ))}
          )
        </WordDetails>
      )}
    </div>
  );
};

interface TranslationsTextInterface {
  bookExercise?: BookExercise;
  size: 'small' | 'large';
  googleTranslation?: string;
  errorMessage?: string;
}

const TranslationsDetailsDiv = ({ bookExercise, size, googleTranslation, errorMessage }: TranslationsTextInterface) => {
  return (
    <>
      {errorMessage ? (
        <TextDiv isInDatabase={false} className="text-text-error">{errorMessage}</TextDiv>
      ) : (
        <div className={`flex flex-col ${size === 'large' ? 'text-lg gap-1.5' : 'gap-0'}`}>
          {bookExercise?.translationId && <LangFromDetailsDiv bookExercise={bookExercise} size={size} /> }
          <LangToDetailsDiv bookExercise={bookExercise} googleTranslation={googleTranslation} size={size} />
        </div>
      )}
    </>
  );
};

export default TranslationsDetailsDiv;
