import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../authentification/AuthContext';
import MenuIconLight from '../../assets/imagesApp/menu-03-light.svg';
import MenuIconDark from '../../assets/imagesApp/menu-03-dark.svg';
import { useLanguage } from '../../languages/LanguageContext';
import CloseIconLight from '../../assets/imagesApp/x-close-light.svg';
import CloseIconDark from '../../assets/imagesApp/x-close-dark.svg';
import Button from '../baseComponents/Button';
import NavbarStatsIcon from '../../assets/imagesApp/navbar-stats.svg';
import NavbarLibraryIcon from '../../assets/imagesApp/navbar-library.svg';
import NavbarSettingsIcon from '../../assets/imagesApp/navbar-settings.svg';
import NavbarExercisesIcon from '../../assets/imagesApp/navbar-exercises.svg';
import { NavigationState, PageUrl, Theme } from '../../types';

const Navbar: React.FC = () => {
    return (
        <>
            <div className="hidden lg:block ">
                <DesktopNavbar />
            </div>
            <div className="hidden md:block lg:hidden">
                <MenuSidebarMediumScreen />
            </div>
            <MobileNavbar />
        </>
    );
};

export default Navbar;


const DesktopNavbar: React.FC = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { getTextLangFrom } = useLanguage();
    const { pathname } = useLocation();

    return (
        <div className="relative w-[280px] h-screen">
            <div className="fixed w-[280px] flex flex-col border-r border-border-secondary bg-bg-primary h-full">
                <div className="flex flex-col flex-1 gap-6 pt-8 px-6"> 
                    <button className="text-[28px] font-semibold text-left" onClick={() => navigate(PageUrl.Library)} >
                        Booklet
                    </button>
                    <div className="flex flex-col gap-1">
                        <Button
                            variant='menuDesktop'
                            onClick={() => navigate(PageUrl.Library, { state: { navigationState: location?.state?.navigationState as NavigationState } })}
                            isActive={pathname === PageUrl.Library}
                            text={getTextLangFrom('word-library')}
                            leadingIcon={NavbarLibraryIcon} 
                        />
                        <Button 
                            variant='menuDesktop'
                            onClick={() => navigate(PageUrl.Exercises, { state: { navigationState: location?.state?.navigationState as NavigationState } })}
                            isActive={pathname === PageUrl.Exercises}
                            text={getTextLangFrom('word-exercises')}
                            leadingIcon={NavbarExercisesIcon} 
                        />
                    </div>
                </div>
                <div className="pb-8 px-6">
                    <div className="flex flex-col gap-1">
                        <Button 
                            variant='menuDesktop'
                            onClick={() => navigate(PageUrl.Profile, { state: { navigationState: location?.state?.navigationState as NavigationState } })}
                            isActive={pathname === PageUrl.Profile}
                            text={getTextLangFrom('StatisticsPage-title')}
                            leadingIcon={NavbarStatsIcon}
                        />
                        <Button 
                            variant='menuDesktop'
                            onClick={() => navigate(PageUrl.Settings, { state: { navigationState: location?.state?.navigationState as NavigationState } })}
                            isActive={pathname === PageUrl.Settings}
                            text={getTextLangFrom('SettingsPage-title')}
                            leadingIcon={NavbarSettingsIcon}
                        />
                        <Button 
                            variant='menuDesktop'
                            onClick={logout} 
                            text={getTextLangFrom('word-log-out')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};


const MenuSidebarMediumScreen: React.FC = () => {
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();
    const CloseIcon = user && user.theme.includes('dark') ? CloseIconDark : CloseIconLight;
    const MenuIcon = user && user.theme.includes('dark') ? MenuIconDark : MenuIconLight;
    const overlayRef = useRef<HTMLDivElement>(null);

    const toggleNavbar = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (overlayRef.current && !overlayRef.current.contains(event.target as Node)) {
                setIsNavbarOpen(false);
            }
        };

        if (isNavbarOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
            document.body.style.overflow = 'hidden'; // Prevent scrolling
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.body.style.overflow = ''; // Re-enable scrolling
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.body.style.overflow = ''; // Ensure scrolling is re-enabled on unmount
        };
    }, [isNavbarOpen]);

    return (
        <>
            <div className="flex justify-between items-center bg-bg-primary py-0 px-6 border-b border-border-secondary h-16">
                <button className="text-2xl font-semibold text-left" onClick={() => navigate(PageUrl.Library)} >
                    Booklet
                </button>
                <Button 
                    variant='tertiary'
                    onClick={toggleNavbar}
                    leadingIcon={MenuIcon}
                    iconOnly={true}
                />
            </div>
            {isNavbarOpen && (
                <div 
                    ref={overlayRef} 
                    onClick={() => setIsNavbarOpen(false)} 
                    className="fixed z-50 inset-0 bg-bg-overlay backdrop-blur flex justify-between"
                >
                        
                        <DesktopNavbar />
                        <Button 
                            variant='tertiary'
                            onClick={toggleNavbar}
                            leadingIcon={CloseIcon}
                            iconOnly={true}
                            className="mt-3 mr-6"
                        />
                </div>
            )}
        </>
    );
};


const MobileNavbar: React.FC = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const location = useLocation();

    return (
        <div className="fixed bottom-0 left-0 p-4 h-[70px] w-full flex items-center justify-between sm:justify-around
                bg-bg-primary border-t border-border-secondary z-50 md:hidden">
            <Button 
                onClick={() => navigate(PageUrl.Library, { state: { navigationState: location?.state?.navigationState as NavigationState } })}
                variant='menuMobile'
                leadingIcon={NavbarLibraryIcon}
                isActive={pathname === PageUrl.Library}
            />
            <Button 
                onClick={() => navigate(PageUrl.Exercises, { state: { navigationState: location?.state?.navigationState as NavigationState } })}
                variant='menuMobile'
                leadingIcon={NavbarExercisesIcon}
                isActive={pathname === PageUrl.Exercises}
            />
            <Button 
                onClick={() => navigate(PageUrl.Profile, { state: { navigationState: location?.state?.navigationState as NavigationState } })}
                variant='menuMobile'
                leadingIcon={NavbarStatsIcon}
                isActive={pathname === PageUrl.Profile}
            />
            <Button 
                onClick={() => navigate(PageUrl.Settings, { state: { navigationState: location?.state?.navigationState as NavigationState } })}
                variant='menuMobile'
                leadingIcon={NavbarSettingsIcon}
                isActive={pathname === PageUrl.Settings}
            />
        </div>
    );
};