import React from 'react';
import ChevronRight from '../../../assets/imagesApp/chevron-right-white.svg';
import DisabledChevronGray from '../../../assets/imagesApp/chevron-right-disabled.svg';
import { Card, ExerciseState, getButtonVariantForExerciseState } from '../../../types';
import FooterControls from '../../appComponents/FooterControls';
import { useLanguage } from '../../../languages/LanguageContext';
import { ResultsDisplay } from '../GameComponents/FooterExerciseInfo';
import Button from '../../baseComponents/Button';

interface MultipleChoiceControlsProps {
  exerciseState: ExerciseState;
  card: Card;
  isActionButtonDisabled: boolean;
  onNextButtonClick: () => void;
}

const MultipleChoiceControls: React.FC<MultipleChoiceControlsProps> = ({ 
  exerciseState, 
  card, 
  isActionButtonDisabled, 
  onNextButtonClick,
}) => {
  const trailingIcon = isActionButtonDisabled ? DisabledChevronGray : ChevronRight;
  const { getTextLangFrom } = useLanguage();

  return (
    <FooterControls exerciseState={exerciseState}>
      <ResultsDisplay
        lemmaLangTo={card?.lemmaLangTo}
        exampleLangTo={card?.exampleLangTo}
        exerciseState={exerciseState}
        pos={card?.pos}
      />
      <div className="footer-controls-action-div">
        <Button 
          size="control"
          trailingIcon={trailingIcon}
          onClick={onNextButtonClick}
          disabled={isActionButtonDisabled}
          variant={getButtonVariantForExerciseState(exerciseState)}
          text={getTextLangFrom('word-next')}
        />
      </div>
    </FooterControls>
  );
};

export default MultipleChoiceControls;
