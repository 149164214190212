import React, { useState, useEffect, useCallback } from 'react';
import { Book, PageUrl } from '../../types';
import { useLanguage } from '../../languages/LanguageContext';
import { useLocation, useNavigate } from 'react-router-dom';
import FooterControls from '../appComponents/FooterControls';
import { updateBook } from '../../api/Books';
import { MainLayout } from '../baseComponents/Layout';
import InputField from '../baseComponents/Input';
import DropArea from './DropArea';
import Button from '../baseComponents/Button';
import { useAuth } from '../../authentification/AuthContext';

const BookEditPage: React.FC = () => {
  // TODO set max file size
  const location = useLocation();
  const navigate = useNavigate();
  const { getTextLangFrom } = useLanguage();
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [book, setBook] = useState<Book | null>(null);
  const [title, setTitle] = useState<string>('');
  const [author, setAuthor] = useState<string>('');
  const [cover, setCover] = useState<string>('');
  const { user } = useAuth();

  useEffect(() => {
    if (!location.state || !location.state.Book) {
      navigate(PageUrl.Library);
      return;
    }
    const Book = location.state.Book;
    setBook(Book);
    setTitle(Book.title);
    setAuthor(Book.author);
    setCover(Book.coverImage);
  }, [location, navigate]);

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
    const file = event.dataTransfer.files?.[0];
    if (file && book && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      book.coverImage = URL.createObjectURL(file);
    }
  }, [book]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(true);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && book && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg')) {
      setCover(URL.createObjectURL(file));
    }
  };

  const handleSave = useCallback(async () => {
    if (book) {
      const formData = new FormData();
      formData.append('bookId', book.id.toString());
      formData.append('userId', user?.id.toString() || '');
      formData.append('title', title || getTextLangFrom('BookEditPage-unknown-title'));
      formData.append('author', author || getTextLangFrom('BookEditPage-unknown-author'));
      if (cover) {
        const blob = await fetch(cover).then(r => r.blob());
        formData.append('cover', blob, 'cover.jpg');
      }
      updateBook(formData);
      navigate(PageUrl.Library);
    }
  }, [book, title, author, cover, user, navigate, getTextLangFrom]);

  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (event.key === 'Enter' ) {
        event.preventDefault();
        handleSave();
      }
        
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSave]);


  return (
    <MainLayout
      hasNavbar={false}
      title={getTextLangFrom('BookEditPage-title')}
    >
      <InputField
        label={getTextLangFrom('word-title')}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder={getTextLangFrom('BookEditPage-title-placeholder')}
      />
      <InputField
        label={getTextLangFrom('word-author')}
        value={author}
        onChange={(e) => setAuthor(e.target.value)}
        placeholder={getTextLangFrom('BookEditPage-author-placeholder')}
      />
      <div className="flex flex-col gap-5">
        <DropArea
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          // onDragLeave={handleDragLeave}
          onClick={() => document.getElementById('droparea-upload')?.click()}
          handleFileChange={handleFileChange}
          isLoading={false}
          getTextLangFrom={getTextLangFrom}
          supportedFormats='.jpg, .jpeg, .png'
          fileImage={cover}
          label={getTextLangFrom('word-cover')}
        />
      </div>
      <FooterControls>
        <div className="footer-controls-action-div">
          <Button 
            variant="secondary"
            size="control"
            onClick={() => navigate(PageUrl.Library)}
            text={getTextLangFrom('word-exit')}
          />
          <Button 
            size="control"
            onClick={handleSave}
            text={getTextLangFrom('BookEditPage-button')}
          />
        </div>
      </FooterControls>
    </MainLayout>
  );
};

export default BookEditPage;
