import React, { useEffect } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import PaginationControls from '../baseComponents/PaginationControl';
import ArrowUpIcon from '../../assets/images/arrow-up.svg';
import ArrowDownIcon from '../../assets/images/arrow-down.svg';
import EmptyState from './EmptyState';
import { EmptyStateProps } from '../../types';

interface TableProps<T> {
  data: T[];
  columns: ColumnDef<T>[];
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  idInitialSortKey?: string;
  isInitialSortAsc?: boolean;
  hiddenColumnsIds?: string[];
  emptyStateProps?: EmptyStateProps
}

export const Table: React.FC<TableProps<any>> = ({ data, columns, sorting, setSorting, idInitialSortKey, isInitialSortAsc, hiddenColumnsIds, emptyStateProps }) => {
  const table = useReactTable({
      columns,
      data,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      onSortingChange: setSorting,
      state: { sorting },
      initialState: {
          pagination: { pageIndex: 0, pageSize: 20 },
      },
  });

  useEffect(() => {
    // Set default sorting
    if (idInitialSortKey && isInitialSortAsc !== undefined) {
      setSorting([{ id: idInitialSortKey, desc: !isInitialSortAsc }]);
    }
  }, [idInitialSortKey, isInitialSortAsc, setSorting]);

  return (
    <div className="table-and-pagination-wrapper">
      {data.length === 0 && emptyStateProps ? (
          <EmptyState {...emptyStateProps} />
        ) : (
          <>
      <table className="table">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <>
                {(!hiddenColumnsIds || !hiddenColumnsIds.includes(header.id)) && (
                  <th key={header.id} colSpan={header.colSpan}
                    style={{
                      width: header.getSize() !== 150 ? header.getSize() : undefined,
                      padding: '0px',
                    }}
                  >
                    <div 
                      className={
                        "table-header-bottom " +
                        (header.column.getCanSort() ? 'cursor-pointer select-none' : '')
                      }
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getIsSorted() === 'asc' && <img className='table-icon-sorting' src={ArrowUpIcon} alt="Up arrow" />}
                      {header.column.getIsSorted() === 'desc' && <img className='table-icon-sorting' src={ArrowDownIcon} alt="Down arrow" />}
                    </div>
                  </th>
                )}
              </>
            ))}
              </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                // Check if meta.isVisible is set to false, and skip rendering if so
                <>
                  {(!hiddenColumnsIds || !hiddenColumnsIds.includes(cell.column.id)) && (
                    <td key={cell.id} className="table-cell">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  )}
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationControls table={table} />
      </>
      )}
    </div>
  );
}

export default Table;