import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MultipleChoiceOptions from './MultipleChoiceOptions';
import MultipleChoiceControls from './MultipleChoiceControls';
import { ExerciseState, GameMode, GameState, GameTag } from '../../../types';
import { useAuth } from '../../../authentification/AuthContext';
import useMultipleChoiceKeyboardControl from './useMultipleChoiceKeyboardControls';
import HeaderWithBubble from '../GameComponents/HeaderWithBubble';
import { MainLayout } from '../../baseComponents/Layout';
import handleTerminateVocabularyGame from '../../../utils/terminateVocabularyGame';
import useInitializeCard from '../GameComponents/useInitializeCard';
import ModalInterruptGame from '../GameComponents/ModalInterruptGame';
import GameProgressBar from '../GameComponents/GameProgressBar';

const MultipleChoicePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { gameMode, chatId, nextExerciseType: exerciseType, cards, numberCardsToReview } = location?.state?.gameState as GameState;
  const { user } = useAuth();
  const [idxCurrentCard, setIdxCurrentCard] = useState<number>(0);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [exerciseState, setExerciseState] = useState<ExerciseState>(ExerciseState.Unanswered);
  const [isActionButtonDisabled, setIsActionButtonDisabled] = useState<boolean>(true);
  const [options, setOptions] = useState<string[]>([]);
  const { cardsWithScores, setCardsWithScores, currentCard, setCurrentCard, initialTime } = useInitializeCard(gameMode, exerciseType, cards);
  const [showModalInterruptGame, setShowModalInterruptGame] = useState(false);

  const handleSubmitResult = useCallback(async (selectedOption: string) => {
    setSelectedAnswer(selectedOption);
    const score = selectedOption === currentCard.lemmaLangTo;
    setExerciseState(score ? ExerciseState.Correct : ExerciseState.Wrong);

    // Update the score of the current card
    let udpatedCards = cardsWithScores.map((card, index) => 
      index === idxCurrentCard ? { ...card, score } : card
    ); 

    // Add the card to the review list if the answer was wrong
    if (score === false) {
      const wrongCard = { ...currentCard, tag: 'review' as GameTag };
      udpatedCards = [...udpatedCards, wrongCard];
    }
    setCardsWithScores(udpatedCards);

    setIsActionButtonDisabled(false);
  }, [cardsWithScores, currentCard, idxCurrentCard, setCardsWithScores]);

  useEffect(() => {
    if (selectedAnswer === null) {
      setIsActionButtonDisabled(true);
    }
  }, [selectedAnswer]);

  const handleNextCard = useCallback(async () => {
    // Move to the next card or terminate the game
    if (idxCurrentCard < cardsWithScores.length - 1) {
      
      setSelectedAnswer(null);
      setExerciseState(ExerciseState.Unanswered);
      setIdxCurrentCard(idxCurrentCard + 1);
      setCurrentCard(cardsWithScores[idxCurrentCard + 1]);
    } else if (user && cardsWithScores) {
      handleTerminateVocabularyGame(gameMode, exerciseType, user, navigate, cardsWithScores, initialTime, true, chatId, numberCardsToReview);
    }
  } , [cardsWithScores, idxCurrentCard, user, initialTime, navigate, setCurrentCard, gameMode, exerciseType, chatId, numberCardsToReview]);

  // Function to shuffle an array (Fisher-Yates shuffle)
  const shuffleOptions = (arr: string[]) => {
    const shuffled = [...arr];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap
    }
    return shuffled;
  };

  // useEffect to update the options whenever otherOptions or selectedAnswer changes
  useEffect(() => {
    const allOptions = [currentCard.lemmaLangTo, ...currentCard.otherLemmasLangTo || []];
    setOptions(shuffleOptions(allOptions));
  }, [currentCard]);

  useMultipleChoiceKeyboardControl(selectedAnswer, cardsWithScores, (index: number) =>  handleSubmitResult(options[index]), handleNextCard);

  return user && (
    <MainLayout hasNavbar={false} hasFooterControls={true}>
      <GameProgressBar 
        cards={cardsWithScores}
        idxCurrentCard={idxCurrentCard}
        setShowModalInterruptGame={setShowModalInterruptGame}
      />
      <HeaderWithBubble gameMode={GameMode.MultipleChoice} card={currentCard} />
      <MultipleChoiceOptions
        options={options}
        selectedAnswer={selectedAnswer} 
        correctAnswer={currentCard.lemmaLangTo}
        exerciseState={exerciseState} 
        onOptionClick={handleSubmitResult} 
      />
      <MultipleChoiceControls 
        exerciseState={exerciseState} 
        card={currentCard}
        isActionButtonDisabled={isActionButtonDisabled}
        onNextButtonClick={handleNextCard} 
      />
      <ModalInterruptGame
        isOpen={showModalInterruptGame}
        setIsOpen={setShowModalInterruptGame}
        onCancel={() => handleTerminateVocabularyGame(gameMode, exerciseType, user, navigate, cardsWithScores, initialTime, false, chatId, numberCardsToReview, true)}
      />
    </MainLayout>
  );
};

export default MultipleChoicePage;
