import { useEffect } from 'react';
import { Card } from '../../../types';

const useMultipleChoiceKeyboardControl = (
  selectedAnswer: string | null,
  cards: Card[],
  handleAnswerShortcut: (index: number) => void,
  handleNextButtonClick: () => void
) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const key = event.key;
      const index = parseInt(key) - 1;

      if (selectedAnswer === null && !isNaN(index) && index >= 0 && index < cards.length) {
        handleAnswerShortcut(index);
      } else if (selectedAnswer && (event.key === 'Enter' || event.key === 'ArrowDown' || event.key === ' ' || event.key === 'ArrowRight')) {
        handleNextButtonClick();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedAnswer, cards, handleNextButtonClick, handleAnswerShortcut]);
};

export default useMultipleChoiceKeyboardControl;
